import React from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addMyGlossary } from "../store/actions/glossary";

const useStyles = makeStyles({
  btn: {
    padding: 20,
  },
  icon: {
    marginLeft: 10,
  },
});

export default function AddNewTermFooter() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    dispatch(addMyGlossary(data));
    handleClose();
  };

  return (
    <div>
      <Button
        className={classes.btn}
        color="primary"
        fullWidth
        onClick={handleClickOpen}
      >
        Add new term
        <AddCircleIcon className={classes.icon} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">Add new term</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Term"
              type="text"
              name="terms"
              fullWidth
              inputRef={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
              error={errors.terms}
              helperText={errors.terms?.message}
            />
            <TextField
              margin="dense"
              label="Meaning"
              name="defination"
              type="text"
              fullWidth
              inputRef={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
              error={errors.defination}
              helperText={errors.defination?.message}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
