import React, { useEffect, useMemo } from "react";
import {
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateMyGlossary } from "../store/actions/glossary";

// const useStyles = makeStyles({
//   btn: {
//     padding: 20,
//   },
//   icon: {
//     marginLeft: 10,
//   },
// });

export default function EditTermDialog({ id }) {
  const dispatch = useDispatch();
  const glossary = useSelector((state) => state.glossary);
  const [open, setOpen] = React.useState(false);

  const item = glossary.myGlossary.find((el) => el.id === id);

  const defaultValues = useMemo(
    () => ({
      defination: item?.defination,
      terms: item?.terms,
    }),
    [item]
  );

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const onSubmit = (data) => {
    dispatch(updateMyGlossary(data, id));
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">Edit term</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Term"
              type="text"
              name="terms"
              fullWidth
              inputRef={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
              error={errors.terms}
              helperText={errors.terms?.message}
            />
            <TextField
              margin="dense"
              label="Meaning"
              name="defination"
              type="text"
              fullWidth
              inputRef={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
              error={errors.defination}
              helperText={errors.defination?.message}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
