import React, { useEffect, useMemo } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  CHECK_USERNAME,
  checkUsername,
  clear,
  getProfile,
  updateProfile,
} from "../store/actions/user";
import { useHistory } from "react-router-dom";
import { UPDATE_PROFILE } from "../store/actions/user";
import { Loading, MaskedInput } from "../components";
import { Header } from "../layout";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
  title: {
    marginBottom: 20,
  },
  input: {
    marginBottom: 50,
  },
});

const AccountDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const defaultValues = {
    first_name: user.profile.first_name,
    last_name: user.profile.last_name,
    user_name: user.profile.user_name,
    company_name: user.profile.company_name,
    phone: user.profile.phone === "" ? "(1  )   -    " : user.profile.phone,
    email: user.profile.email,
  };

  const { register, handleSubmit, errors, watch, setError, reset } = useForm({
    defaultValues: useMemo(() => defaultValues, [user.profile]),
    mode: "onChange",
  });

  const username = watch("user_name", "");

  useEffect(() => {
    dispatch(getProfile());

    return () => {
      dispatch(clear());
    };
  }, []);

  useEffect(() => {
    if (user.completedUserUpdate) {
      history.push("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.completedUserUpdate]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profile]);

  useEffect(() => {
    if (
      username !== "" &&
      !errors.user_name &&
      username !== user.profile.user_name
    ) {
      dispatch(checkUsername(username));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    if (user.error[CHECK_USERNAME.ERROR]) {
      setError("user_name", {
        type: "unique",
        message: "This username is already taken",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.error[CHECK_USERNAME.ERROR]]);

  const onSubmit = (data) => {
    const isEmailUpdated = user.profile.email !== data.email;
    dispatch(updateProfile(data, isEmailUpdated, user.profile.email));
  };

  return (
    <>
      <Helmet>
        <title>Account Details</title>
      </Helmet>
      <Header />
      <Container className={classes.container}>
        {user.isLoading[UPDATE_PROFILE.REQUEST] && <Loading />}
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid
            container
            md={6}
            spacing={3}
            justify="center"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h4"
                align="center"
                className={classes.title}
              >
                Account Information
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="First name"
                fullWidth={true}
                name="first_name"
                type="firstName"
                inputRef={register({ required: "This is required" })}
                error={errors.first_name}
                helperText={errors.first_name?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last name"
                fullWidth={true}
                name="last_name"
                type="lastName"
                inputRef={register({ required: "This is required" })}
                error={errors.last_name}
                helperText={errors.last_name?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email address"
                fullWidth={true}
                name="email"
                type="email"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  minLength: {
                    value: 4,
                    message: "Email should be at least 4 symbols",
                  },
                  maxLength: {
                    value: 254,
                    message: "Email should not be longer then 254 symbols",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Email should have form user@email.com",
                  },
                })}
                error={errors.email}
                helperText={errors.email?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Username"
                fullWidth={true}
                name="user_name"
                type="username"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  minLength: {
                    value: 4,
                    message: "Username should be at least 4 symbols",
                  },
                  maxLength: {
                    value: 254,
                    message: "Username should not be longer then 254 symbols",
                  },
                })}
                error={errors.user_name}
                helperText={errors.user_name?.message}
                InputLabelProps={{
                  shrink: user.is_profile_completed ? true : undefined,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Company name"
                fullWidth={true}
                name="company_name"
                type="company"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                error={errors.company_name}
                helperText={errors.company_name?.message}
                InputLabelProps={{
                  shrink: user.is_profile_completed ? true : undefined,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MaskedInput
                mask="(999) 999-9999"
                label="Phone number"
                fullWidth={true}
                name="phone"
                type="tel"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  pattern: {
                    value: /\(\d{3}\)\s\d{3}-\d{4}/,
                    message: "Phone number should be format (xxx) xxx-xxx",
                  },
                })}
                error={errors.phone}
                helperText={errors.phone?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AccountDetails;
