import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { EditTermDialog, Loading } from "../components";
import {
  ADD_MY_GLOSSARY,
  GET_MY_GLOSSARY,
  DELETE_MY_GLOSSARY,
  UPDATE_MY_GLOSSARY,
  getMyGlossary,
} from "../store/actions/glossary";
import { AddNewTermFooter, renderCellExpand, SearchInput } from "../components";
import DeleteDialog from "../components/DeleteDialog";
import { useLastLocation } from "react-router-last-location";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
  btn: {
    marginBottom: 20,
  },
  table: {
    backgroundColor: "red",
  },
  actionsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  boxWrapper: {
    marginTop: 94,
  },
});

const MyGlossary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const glossary = useSelector((state) => state.glossary);
  const [search, setSearch] = useState("");
  const history = useHistory();
  const lastLocation = useLastLocation();

  const isLastLocationDraft = lastLocation?.pathname.indexOf("draft") !== -1;

  useEffect(() => {
    dispatch(getMyGlossary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtered =
    search === ""
      ? glossary.myGlossary
      : glossary.myGlossary.filter(
          (item) =>
            item.terms.toLowerCase().includes(search.toLowerCase()) ||
            item.defination.toLowerCase().includes(search.toLowerCase())
        );

  const columns = [
    { field: "terms", headerName: "Term", flex: 1 },
    {
      field: "defination",
      headerName: "Definition / Plain-English Substitute",
      flex: 2,
      renderCell: renderCellExpand,
    },
    {
      field: "id",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: ({ value }) => (
        <div className={classes.actionsWrapper}>
          <DeleteDialog type="glossary" id={value} />
          <EditTermDialog id={value} />
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>My glossary</title>
      </Helmet>
      <Header />
      {(glossary.isLoading[DELETE_MY_GLOSSARY.REQUEST] ||
        glossary.isLoading[ADD_MY_GLOSSARY.REQUEST] ||
        glossary.isLoading[UPDATE_MY_GLOSSARY.REQUEST]) && <Loading />}
      <Container className={classes.container}>
        <Grid
          container
          direction="row"
          className={classes.container}
          spacing={3}
        >
          <Grid item xs={12} spacing={3}>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              display="flex"
              className={classes.boxWrapper}
            >
              <Typography variant="h3">My Glossary</Typography>
              {isLastLocationDraft && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(lastLocation?.pathname)}
                >
                  Back to draft
                </Button>
              )}
            </Box>
            <SearchInput
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Search"
            />
            <DataGrid
              loading={glossary.isLoading[GET_MY_GLOSSARY.REQUEST]}
              autoHeight
              rows={filtered}
              columns={columns}
              components={{ Footer: AddNewTermFooter }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MyGlossary;
