import {
  getHeadings as getHeadingsReq,
  getArticle as getArticleReq,
  updateArticle as updateArticleReq,
} from "../../../api";
import { toast } from "material-react-toastify";

const GET_HEADINGS = {
  REQUEST: "GET_HEADINGS_REQUEST",
  SUCCESS: "GET_HEADINGS_SUCCESS",
  ERROR: "GET_HEADINGS_ERROR",
};

const GET_ARTICLE = {
  REQUEST: "GET_ARTICLE_REQUEST",
  SUCCESS: "GET_ARTICLE_SUCCESS",
  ERROR: "GET_ARTICLE_ERROR",
};

const UPDATE_ARTICLE = {
  REQUEST: "UPDATE_ARTICLE_REQUEST",
  SUCCESS: "UPDATE_ARTICLE_SUCCESS",
  ERROR: "UPDATE_ARTICLE_ERROR",
};

const SET_ACTIVE_HEADING = "SET_ACTIVE_HEADING";

const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

const CLEAR_ARTICLE = "CLEAR_ARTICLE";

const SET_LONG_WORDS = "SET_LONG_WORDS";

const SET_SEARCH = "SET_SEARCH";

function updateArticle(id, headingId, data) {
  const thunk = async (dispatch) => {
    try {
      dispatch(request());
      const res = (await updateArticleReq(id, headingId, data)).data;
      dispatch(success({ article: res.data }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  thunk.meta = {
    debounce: {
      time: 500,
      key: "UPDATE_ARTICLE",
    },
  };

  function request() {
    return { type: UPDATE_ARTICLE.REQUEST };
  }

  function success(payload) {
    return { type: UPDATE_ARTICLE.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: UPDATE_ARTICLE.ERROR, payload };
  }

  return thunk;
}

function getArticle(id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await getArticleReq(id)).data;
      dispatch(
        success({
          article: res.data,
          name: res.name,
          reading_ease_total: res.reading_ease_total,
          grade_total: res.grade_total,
          sentence_count_total: res.sentence_count_total,
        })
      );
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: GET_ARTICLE.REQUEST };
  }

  function success(payload) {
    return { type: GET_ARTICLE.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_ARTICLE.ERROR, payload };
  }
}

function getHeadings() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await getHeadingsReq()).data;
      dispatch(success({ headings: res.data }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: GET_HEADINGS.REQUEST };
  }

  function success(payload) {
    return { type: GET_HEADINGS.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_HEADINGS.ERROR, payload };
  }
}

function setActiveHeading(activeHeading) {
  return { type: SET_ACTIVE_HEADING, payload: { activeHeading } };
}

function setNotifications(notifications) {
  return { type: SET_NOTIFICATIONS, payload: { notifications } };
}

function clearArticle() {
  return { type: CLEAR_ARTICLE };
}

function setLongWords(heading_id, longWords) {
  return { type: SET_LONG_WORDS, payload: { heading_id, longWords } };
}

function setSearch(search) {
  return { type: SET_SEARCH, payload: { search } };
}

export {
  getHeadings,
  GET_HEADINGS,
  SET_ACTIVE_HEADING,
  setActiveHeading,
  setNotifications,
  SET_NOTIFICATIONS,
  GET_ARTICLE,
  UPDATE_ARTICLE,
  updateArticle,
  getArticle,
  CLEAR_ARTICLE,
  clearArticle,
  SET_LONG_WORDS,
  setLongWords,
  setSearch,
  SET_SEARCH,
};
