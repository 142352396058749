import React from "react";
import { ButtonGroup, IconButton } from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setActiveHeading } from "../store/actions/article";

const NavigateHeaders = () => {
  const { activeHeading, headings } = useSelector((state) => state.article);
  const dispatch = useDispatch();

  const navigateBefore = () => {
    dispatch(setActiveHeading(activeHeading.id - 1));
  };

  const navigateNext = () => {
    dispatch(setActiveHeading(activeHeading.id + 1));
  };

  return (
    <ButtonGroup>
      <IconButton
        disabled={headings[0]?.id === activeHeading.id}
        onClick={navigateBefore}
      >
        <NavigateBefore />
      </IconButton>
      <IconButton
        disabled={headings[headings.length - 1]?.id === activeHeading.id}
        onClick={navigateNext}
      >
        <NavigateNext />
      </IconButton>
    </ButtonGroup>
  );
};

export default NavigateHeaders;
