import React from "react";
import { Route as ReactRoute, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const UserRoute = ({ children, publicRoute, privateRoute, ...rest }) => {
  const { isAuthenticated, is_profile_completed, isAdmin } = useSelector(
    (state) => state.user
  );

  if (publicRoute) {
    if (!isAuthenticated) {
      return <ReactRoute {...rest} render={() => children} />;
    }
    if (isAdmin) {
      return <Redirect to="/dashboard-admin" />;
    } else {
      return <Redirect to="/home" />;
    }
  }

  if (privateRoute) {
    if (isAuthenticated) {
      if (!is_profile_completed && rest.path !== "/account-details") {
        return <Redirect to="/account-details" />;
      } else return <ReactRoute {...rest} render={() => children} />;
    }
  }

  return <Redirect to="/signin" />;
};

export default UserRoute;
