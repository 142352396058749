import { toast } from "material-react-toastify";
import {
  deleteDraft as deleteDraftReq,
  getAllDrafts as getAllDraftsReq,
  createDraft,
  updateDraft as updateDraftReq,
  getDraft as getDraftReq,
} from "../../../api";

const GET_ALL_DRAFTS = {
  REQUEST: "GET_ALL_DRAFTS_REQUEST",
  SUCCESS: "GET_ALL_DRAFTS_SUCCESS",
  ERROR: "GET_ALL_DRAFTS_ERROR",
};

const GET_DRAFT = {
  REQUEST: "GET_DRAFT_REQUEST",
  SUCCESS: "GET_DRAFT_SUCCESS",
  ERROR: "GET_DRAFT_ERROR",
};

const DELETE_DRAFT = {
  REQUEST: "DELETE_DRAFT_REQUEST",
  SUCCESS: "DELETE_DRAFT_SUCCESS",
  ERROR: "DELETE_DRAFT_ERROR",
};

const UPDATE_DRAFT = {
  REQUEST: "UPDATE_DRAFT_REQUEST",
  SUCCESS: "UPDATE_DRAFT_SUCCESS",
  ERROR: "UPDATE_DRAFT_ERROR",
};

const CREATE_DRAFT = {
  REQUEST: "CREATE_DRAFT_REQUEST",
  SUCCESS: "CREATE_DRAFT_SUCCESS",
  ERROR: "CREATE_DRAFT_ERROR",
};

const CLEAR_ACTIVE_DRAFT = "CLEAR_ACTIVE_DRAFT";

const CLEAR_REDIRECT = "CLEAR_REDIRECT";

function getAllDrafts() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await getAllDraftsReq()).data;
      dispatch(success({ allDrafts: res.data }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: GET_ALL_DRAFTS.REQUEST };
  }

  function success(payload) {
    return { type: GET_ALL_DRAFTS.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_ALL_DRAFTS.ERROR, payload };
  }
}

function deleteDraft(id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await deleteDraftReq(id);
      dispatch(success({ id }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: DELETE_DRAFT.REQUEST };
  }

  function success(payload) {
    return { type: DELETE_DRAFT.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: DELETE_DRAFT.ERROR, payload };
  }
}

function addDraft(data) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await createDraft(data)).data;
      dispatch(success({ item: res.data }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: CREATE_DRAFT.REQUEST };
  }

  function success(payload) {
    return { type: CREATE_DRAFT.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: CREATE_DRAFT.ERROR, payload };
  }
}

function updateDraft(data, id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await updateDraftReq(data, id)).data;
      dispatch(success({ updatedItem: res.data }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: UPDATE_DRAFT.REQUEST };
  }

  function success(payload) {
    return { type: UPDATE_DRAFT.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: UPDATE_DRAFT.ERROR, payload };
  }
}

function getDraft(id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await getDraftReq(id)).data;
      dispatch(success({ item: res.data }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: GET_DRAFT.REQUEST };
  }

  function success(payload) {
    return { type: GET_DRAFT.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_DRAFT.ERROR, payload };
  }
}

function clearActiveDraft() {
  return {
    type: CLEAR_ACTIVE_DRAFT,
  };
}

function clearRedirect() {
  return {
    type: CLEAR_REDIRECT,
  };
}

export {
  GET_ALL_DRAFTS,
  getAllDrafts,
  deleteDraft,
  addDraft,
  updateDraft,
  getDraft,
  CREATE_DRAFT,
  DELETE_DRAFT,
  GET_DRAFT,
  UPDATE_DRAFT,
  CLEAR_ACTIVE_DRAFT,
  CLEAR_REDIRECT,
  clearActiveDraft,
  clearRedirect,
};
