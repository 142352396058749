import instance from "../instance";

export const getUserStatistics = async () => {
  try {
    return await instance.get("/admin/users");
  } catch (e) {
    throw e.response.data.message;
  }
};

export const getDraftStatistics = async (page) => {
  try {
    return await instance.get(`/admin/draft/${page}`);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const getReceiptPdf = async (data) => {
  try {
    return await instance.post(`/admin/receipt`, data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const getAppFeedback = async (page, params = {}) => {
  try {
    return await instance.get(`/feedback/app/${page}`, { params });
  } catch (e) {
    throw e.response.data.message;
  }
};

export const setFeedbackStatus = async (status, id) => {
  try {
    return await instance.put(`/admin/feedback/status/${status}/${id}`);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const markFeedback = async (read, id) => {
  const readToNum = read ? 1 : 0;
  try {
    return await instance.put(`/admin/feedback/read/${readToNum}/${id}`);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const downloadFeedbackAttachments = async (filename) => {
  try {
    return await instance.get(`/admin/download_file/${filename}`, {
      responseType: "blob",
    });
  } catch (e) {
    throw e.response.data.message;
  }
};
