import instance from "../instance";

export const getAllDrafts = async () => {
  try {
    return await instance.get("/draft");
  } catch (e) {
    throw e.response.data.message;
  }
};

export const getDraft = async (id) => {
  try {
    return await instance.get(`/draft/${id}`);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const deleteDraft = async (id) => {
  try {
    return await instance.delete(`/draft/${id}`);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const updateDraft = async (data, id) => {
  try {
    return await instance.put(`/draft/${id}`, data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const createDraft = async (data) => {
  try {
    return await instance.post("/draft", data);
  } catch (e) {
    throw e.response.data.message;
  }
};
