import React, { useEffect } from "react";
import { Paper, Box, createStyles, List, Typography } from "@material-ui/core";
import { getHeadings, setActiveHeading } from "../store/actions/article";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { HeadingListItem } from "./index";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: "100%",
    },
    list: {
      height: "60vh",
      overflow: "auto",
      width: "100%",
      marginBottom: 10,
    },
    title: {
      marginBottom: 10,
    },
  })
);

const HeadingsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { headings, activeHeading } = useSelector((state) => state.article);

  useEffect(() => {
    dispatch(getHeadings());
  }, []);

  useEffect(() => {
    if (headings.length && activeHeading.id === null) {
      dispatch(setActiveHeading(1));
    }
  }, [headings]);

  return (
    <Box className={classes.wrapper}>
      <Paper>
        <List component="nav" className={classes.list}>
          {headings.map((item, id) => (
            <HeadingListItem item={item} id={id} key={item.name} />
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default HeadingsList;
