import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { downloadArticlePdfPreview } from "../api";
import { useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { blue } from "@material-ui/core/colors";
import { useSelector } from "react-redux";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const useStyles = makeStyles((theme) =>
  createStyles({
    btn: {
      marginTop: 10,
      backgroundColor: blue[500],
      color: "#fff",
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    page: {
      margin: "auto",
    },
    pdfWrapper: {
      "&:hover .controls": {
        opacity: 1,
      },
      "& .react-pdf__Document > canvas": {
        maxWidth: "100%",
        height: "auto !important",
      },
    },
    document: { margin: "auto" },
    controls: {
      position: "absolute",
      bottom: "10%",
      left: "50%",
      background: "white",
      opacity: 0,
      transform: "translateX(-50%)",
      transition: "opacity ease-in-out 0.2s",
      boxShadow: "0 30px 40px 0 rgba(16, 36, 94, 0.2)",
      borderRadius: "4px",
      "& span": {
        font: "inherit",
        fontSize: 15,
        padding: "0 0.5em",
      },
      "& button": {
        width: 44,
        height: 44,
        background: "white",
        border: 0,
        font: "inherit",
        fontSize: 20,
        borderRadius: 4,
        cursor: "pointer",
      },
      "& button:enabled:hover": {
        backgroundColor: "#e6e6e6",
      },
      "& button:first-child": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      "& button:last-child": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  })
);

const PdfPreviewModal = () => {
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const { name } = useSelector((state) => state.article);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdf, setPdf] = useState(null);
  const { id } = useParams();
  const [initialWidth, setInitialWidth] = useState(null);
  const pdfWrapper = useRef();

  const setPdfSize = () => {
    if (pdfWrapper?.current?.getBoundingClientRect) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  };

  const loadDocument = async () => {
    const response = (await downloadArticlePdfPreview(id)).data;
    setPdf("data:application/pdf;base64, " + response.base64);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const classes = useStyles();
  const previewPdf = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadDocument();
    window.addEventListener("resize", setPdfSize);
    setPdfSize();
    return () => {
      window.removeEventListener("resize", setPdfSize);
    };
  }, []);

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondaryLight"
        type="submit"
        onClick={previewPdf}
        fullWidth
        classes={{
          root: classes.btn,
        }}
      >
        Preview Class Notice
      </Button>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle disableTypography sx={{ m: 0, p: 2 }}>
          <Typography variant="h6" component="div">
            {`${name} preview`}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 2 }}>
          <div
            style={{
              display: "flex",
            }}
            className={classes.pdfWrapper}
          >
            <div style={{ width: "90vw", display: "flex" }} ref={pdfWrapper}>
              {pdf ? (
                <>
                  <Document
                    file={pdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={classes.document}
                  >
                    <Page
                      className={classes.page}
                      pageNumber={pageNumber}
                      width={initialWidth}
                    />
                  </Document>
                  <div className={`${classes.controls} controls`}>
                    <button
                      disabled={pageNumber <= 1}
                      type="button"
                      onClick={previousPage}
                      className="button"
                    >
                      ‹
                    </button>
                    <span>{`${pageNumber} of ${numPages}`}</span>
                    <button
                      type="button"
                      onClick={nextPage}
                      disabled={pageNumber >= numPages}
                      className="button"
                    >
                      ›
                    </button>
                  </div>
                </>
              ) : (
                <CircularProgress
                  style={{
                    margin: "auto",
                  }}
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ m: 0, p: 1 }}>
          <Button onClick={handleClose}>Return to draft</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PdfPreviewModal;
