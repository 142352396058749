import { BrowserRouter, Switch } from "react-router-dom";
import { AdminRoute, UserRoute } from "./components";
import { Helmet } from "react-helmet";
import { LastLocationProvider } from "react-router-last-location";

import {
  ConfirmRegister,
  ForgotPasswordRequest,
  Home,
  SignIn,
  SignUp,
  ForgotPasswordConfirm,
  AccountDetails,
  IfGlossary,
  MyGlossary,
  Draft,
  Analytics,
  AdminDashboard,
  Receipts,
  Feedback,
} from "./pages";
import { Theme } from "./providers";
import { ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <Theme>
      <Helmet defaultTitle="Impact Fund" titleTemplate="Impact Fund - %s" />
      <BrowserRouter>
        <LastLocationProvider>
          <Switch>
            <UserRoute path="/" exact />
            <UserRoute path="/signup" exact publicRoute>
              <SignUp />
            </UserRoute>
            <UserRoute path="/signin" exact publicRoute>
              <SignIn />
            </UserRoute>
            <UserRoute path="/confirm" exact publicRoute>
              <ConfirmRegister />
            </UserRoute>
            <UserRoute path="/forgot" exact publicRoute>
              <ForgotPasswordRequest />
            </UserRoute>
            <UserRoute path="/forgot/:token" exact publicRoute>
              <ForgotPasswordConfirm />
            </UserRoute>
            <UserRoute path="/home" exact privateRoute>
              <Home />
            </UserRoute>
            <UserRoute path="/account-details" exact privateRoute>
              <AccountDetails />
            </UserRoute>
            <UserRoute path="/if-glossary" exact privateRoute>
              <IfGlossary />
            </UserRoute>
            <UserRoute path="/my-glossary" exact privateRoute>
              <MyGlossary />
            </UserRoute>
            <UserRoute path="/draft/:id" exact privateRoute>
              <Draft />
            </UserRoute>
            <UserRoute path="/draft/:id/analytics" exact privateRoute>
              <Analytics />
            </UserRoute>
            <AdminRoute path="/dashboard-admin" exact>
              <AdminDashboard />
            </AdminRoute>
            <AdminRoute path="/receipts" exact>
              <Receipts />
            </AdminRoute>
            <AdminRoute path="/user-feedback" exact>
              <Feedback />
            </AdminRoute>
          </Switch>
        </LastLocationProvider>
      </BrowserRouter>
      <ToastContainer />
    </Theme>
  );
};

export default App;
