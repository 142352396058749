import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  createStyles,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../layout";
import { useDispatch, useSelector } from "react-redux";
import {
  DraftCard,
  DraftModal,
  HomeFeedback,
  Loading,
  SearchInput,
} from "../components";
import { DatePicker } from "@material-ui/pickers";
import {
  GET_ALL_DRAFTS,
  CREATE_DRAFT,
  DELETE_DRAFT,
  getAllDrafts,
  clearRedirect,
  clearActiveDraft,
} from "../store/actions/draft";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { clearArticle } from "../store/actions/article";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: "100%",
      paddingTop: 94,
      paddingBottom: 50,
    },
    gridContainer: {
      backgroundColor: theme.palette.grey["200"],
    },
    btn: {
      marginBottom: 20,
    },
    headerTitle: {
      backgroundColor: theme.palette.grey["500"],
      padding: theme.spacing(2),
    },
    headerSearch: {
      backgroundColor: theme.palette.grey["400"],
      padding: "10px 0",
    },
    cardsGrid: {
      padding: "15px 0 15px 15px",
      [theme.breakpoints.down("xs")]: {
        padding: "15px 0 0 0",
      },
    },
    cardsWrapper: {
      maxHeight: ({ cardsHeight }) => cardsHeight - theme.spacing(4) ?? 0,
      overflowY: "auto",
      width: "100%",
    },
    contentWrapper: {
      backgroundColor: theme.palette.grey["200"],
    },
    rowCalendarWrapper: {
      padding: "15px",
      "& > .MuiPickersStaticWrapper-staticWrapperRoot, .MuiPickersBasePicker-pickerView":
        {
          width: "100%",
          maxWidth: "unset",
        },
    },
    noResults: {
      textAlign: "center",
    },
    cardsRoot: {
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    title: {
      color: "white",
    },
  })
);

const Home = () => {
  const calendarRef = useRef();
  const history = useHistory();
  const classes = useStyles({ cardsHeight: calendarRef.current?.offsetHeight });
  const dispatch = useDispatch();
  const draft = useSelector((state) => state.draft);
  const [search, onChangeSearch] = useState("");
  const filtered =
    search === ""
      ? draft.allDrafts
      : draft.allDrafts.filter((draft) =>
          draft.draft_name.toLowerCase().includes(search.toLowerCase())
        );

  useEffect(() => {
    dispatch(getAllDrafts());
    dispatch(clearActiveDraft());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (draft.redirectTo) {
      dispatch(clearArticle());
      history.push(`/draft/${draft.redirectTo}`);
    }

    return () => {
      dispatch(clearRedirect());
    };
  }, [draft.redirectTo]);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Header />
      <Container className={classes.container}>
        {(draft.isLoading[GET_ALL_DRAFTS.REQUEST] ||
          draft.isLoading[CREATE_DRAFT.REQUEST] ||
          draft.isLoading[DELETE_DRAFT.REQUEST]) && <Loading />}
        <Grid container className={classes.gridContainer}>
          <Grid
            container
            item
            md={8}
            xs={12}
            className={classes.headerTitle}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={7}>
              <Typography variant="h6" className={classes.title}>
                Your active drafts
              </Typography>
            </Grid>
            <Grid item container justify="flex-end" xs={5}>
              <DraftModal type="create" />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md
            className={classes.headerSearch}
            justify="center"
          >
            <Grid item xs={10}>
              <SearchInput
                value={search}
                onChange={(e) => onChangeSearch(e.target.value)}
                noMargin
                placeholder="Search all the documents"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.contentWrapper}>
          <Grid
            container
            item
            md={8}
            xs={12}
            alignContent="flex-start"
            className={classes.cardsGrid}
          >
            <Card
              elevation={0}
              square
              className={classes.cardsWrapper}
              classes={{ root: classes.cardsRoot }}
            >
              <CardContent>
                {filtered.length || draft.isLoading[GET_ALL_DRAFTS.REQUEST] ? (
                  draft.allDrafts.map((item) => (
                    <DraftCard item={item} key={item.id} />
                  ))
                ) : (
                  <Typography variant="body1" className={classes.noResults}>
                    No results found
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            className={classes.rowCalendarWrapper}
            ref={calendarRef}
          >
            <DatePicker
              autoOk
              variant="static"
              openTo="date"
              orientation="portrait"
            />
            <HomeFeedback />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Home;
