import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Controller } from "react-hook-form";

const DateInput = ({ name, control, rules, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      rules={rules}
      render={({ ref, ...rest }) => (
        <KeyboardDatePicker
          clearable
          format="YYYY-MM-DD"
          {...props}
          {...rest}
          onChange={(e, date) => rest.onChange(date)}
        />
      )}
    />
  );
};

export default DateInput;
