import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
  createStyles,
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";

import { DataGrid } from "@material-ui/data-grid";

import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import printJs from "print-js";

import {
  renderCellExpand,
  Loading,
  OverallAnalyticsTable,
} from "../components";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  GET_ARTICLE,
  getArticle,
  setActiveHeading,
  getHeadings,
  GET_HEADINGS,
} from "../store/actions/article";
import EditIcon from "@material-ui/icons/Edit";
import { downloadArticlePdf } from "../api";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "100%",
    },
    boxWrapper: {
      marginTop: 94,
    },
    btn: {
      marginBottom: 20,
    },
    table: {
      backgroundColor: "red",
    },
    dataGrid: {
      "& .MuiDataGrid-colCellTitle": {
        lineHeight: 1.5,
        textAlign: "center",
        whiteSpace: "normal",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          lineHeight: "unset",
          textAlign: "unset",
          whiteSpace: "unset",
          alignItems: "unset",
          justifyContent: "unset",
          display: "unset",
        },
      },
    },
    printBtn: {
      marginLeft: 100,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginTop: 20,
        width: "100%",
      },
    },
    analyticsTable: {
      flexWrap: "wrap",
    },
  })
);

const Analytics = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { article, isLoading } = useSelector((state) => state.article);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getArticle(id));
    dispatch(getHeadings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = (headingId) => {
    dispatch(setActiveHeading(headingId));
    history.push(`/draft/${id}`);
  };

  const columns = [
    {
      field: "headingEdit",
      headerName: "Edit",
      width: 130,
      renderCell: ({ value }) => (
        <IconButton
          variant="outlined"
          color="primary"
          onClick={() => handleClickOpen(value)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "heading_id",
      headerName: "Heading #",
      width: 145,
      sortable: true,
    },
    {
      field: "heading_title",
      headerName: "Heading",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "paragraph",
      headerName: "Section Content",
      flex: 1,
      renderCell: (params) => renderCellExpand({ ...params, html: true }),
    },
    {
      field: "reading_ease",
      headerName: "Reading Ease Score",
      width: 130,
    },
    {
      field: "grade",
      headerName: "Grade Level Score",
      width: 130,
    },
    {
      field: "sentence_count",
      headerName: "# of sentences",
      width: 130,
    },
  ];

  const sortModel = [{ field: "heading_id", sort: "asc" }];

  const print = async () => {
    setLoading(true);
    const response = (await downloadArticlePdf(id)).data;
    await printJs({ printable: response.base64, type: "pdf", base64: true });
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Analytics</title>
      </Helmet>
      <Header />
      <Container className={classes.container}>
        {isLoading[GET_ARTICLE.REQUEST] && <Loading />}
        <Grid
          container
          direction="row"
          className={classes.container}
          spacing={3}
        >
          <Grid item xs={12} spacing={3}>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              display="flex"
              className={classes.boxWrapper}
            >
              <Typography variant="h3">Analytics</Typography>
            </Box>
            <Box
              flexDirection="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginY="30px"
              className={classes.analyticsTable}
            >
              <OverallAnalyticsTable />
              <LoadingButton
                loading={loading}
                variant="contained"
                color="primary"
                className={classes.printBtn}
                onClick={print}
              >
                Print Draft
              </LoadingButton>
            </Box>
            <DataGrid
              loading={
                isLoading[GET_ARTICLE.REQUEST] ||
                isLoading[GET_HEADINGS.REQUEST]
              }
              autoHeight
              rows={article.map((article) => ({
                ...article,
                headingEdit: article.heading_id,
              }))}
              columns={columns}
              sortModel={sortModel}
              className={classes.dataGrid}
              hideFooter
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Analytics;
