import React from "react";
import {
  TableRow,
  Table,
  TableContainer,
  Paper,
  TableCell,
  TableBody,
  TableHead,
  makeStyles,
  Typography,
  createStyles,
} from "@material-ui/core";

import { useSelector } from "react-redux";
import { getEaseDifficulty, getGradeLevel } from "../helpers";

const useStyles = makeStyles((theme) =>
  createStyles({
    table: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
    tableContainer: {
      maxWidth: 500,
      marginLeft: 174,
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
    },
    tableTitleWrapper: {
      backgroundColor: theme.palette.grey["600"],
      padding: 16,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    tableTitle: {
      color: "white",
    },
  })
);

const OverallAnalyticsTable = () => {
  const classes = useStyles();
  const { sentence_count_total, grade_total, reading_ease_total } = useSelector(
    (state) => state.article
  );
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Paper variant="outlined" className={classes.tableTitleWrapper}>
        <Typography className={classes.tableTitle}>Draft Overview</Typography>
      </Paper>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              Reading Ease Score (Weighted Avg.)
            </TableCell>
            <TableCell align="center">
              Grade Level Score (Weighted Avg.)
            </TableCell>
            <TableCell align="center">Total Sentences</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">
              {reading_ease_total > 0 ? reading_ease_total : ""}
              {reading_ease_total > 0 && <br />}
              {getEaseDifficulty(reading_ease_total)}
            </TableCell>
            <TableCell align="center">
              {grade_total > 0 ? grade_total : ""}
              {grade_total > 0 && <br />}
              {getGradeLevel(grade_total)}
            </TableCell>
            <TableCell align="center">
              {sentence_count_total}
              <br />
              Sentences
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OverallAnalyticsTable;
