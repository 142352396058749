import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  logoWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  logoLine: {
    width: 40,
    height: 10,
    backgroundColor: "red",
  },
  secondLine: {
    marginTop: 5,
  },
});

const Logo = () => {
  const classes = useStyles();
  const { isAdmin } = useSelector((state) => state.user);

  return (
    <Link to={isAdmin ? "/dashboard-admin" : "/home"}>
      <div className={classes.logoWrapper}>
        <div className={classes.logoLine} />
        <div className={classNames(classes.logoLine, classes.secondLine)} />
      </div>
    </Link>
  );
};

export default Logo;
