import React, { useEffect } from "react";
import {
  Paper,
  Box,
  createStyles,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  getIfGlossary,
  getMyGlossary,
  getLegalese,
} from "../store/actions/glossary";
import { NotificationIcon } from "../layout";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: "100%",
    },
    list: {
      height: "40vh",
      overflow: "auto",
      width: "100%",
      marginBottom: 10,
    },
    title: {
      marginBottom: 10,
    },
    labelTitle: {
      textAlign: "center",
      fontStyle: "italic",
      fontWeight: "bold",
      color: theme.palette.grey["400"],
    },
    labelSubtitle: {
      marginTop: 5,
      textAlign: "center",
      fontStyle: "italic",
      color: theme.palette.grey["400"],
    },
  })
);

const EditorNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.article);

  useEffect(() => {
    dispatch(getIfGlossary());
    dispatch(getMyGlossary());
    dispatch(getLegalese());
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h5" className={classes.title}>
        Glossary & Text Notifications
      </Typography>
      <Paper>
        <List component="nav" className={classes.list}>
          {notifications.map((notification) => (
            <ListItem key={`${notification.id}${notification.type}`}>
              <NotificationIcon type={notification.type} />
              <ListItemText
                primary={notification.terms}
                secondary={
                  notification.type === "legalese"
                    ? "Consider using plain language"
                    : notification.defination
                }
              />
            </ListItem>
          ))}
          {!notifications.length && (
            <Box
              alignItems="center"
              justifyContent="center"
              display="flex"
              flexDirection="column"
              width="100%"
              height="100%"
              padding="20px"
            >
              <Typography className={classes.labelTitle} variant="body1">
                Nothing to Review
              </Typography>
              <Typography
                className={classes.labelSubtitle}
                variant="body1"
                text
              >
                Start Writing to see feedback.
              </Typography>
            </Box>
          )}
        </List>
      </Paper>
    </Box>
  );
};

export default EditorNotifications;
