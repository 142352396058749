import React, { useEffect, useState } from "react";
import {
  GET_IF_GLOSSARY,
  GET_LEGALESE,
  GET_MY_GLOSSARY,
} from "../store/actions/glossary";

import {
  GET_ARTICLE,
  setLongWords,
  setNotifications,
  updateArticle,
} from "../store/actions/article";

import { CircularProgress, createStyles } from "@material-ui/core";

import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";

import {
  LengthMatcher,
  WordMatcher,
  ExactWordMatcher,
  MultiHighlightDecorator,
} from "draft-js-multi-inline-highlight";
import draftToHtml from "draftjs-to-html";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { getLocations, getArrayTagsHtmlString } from "../helpers";
import { Editor as EditorWysiwyg } from "react-draft-wysiwyg";
import { theme } from "../providers";

const useStyles = makeStyles((theme) =>
  createStyles({
    editor: {
      width: "100%",
      height: "100%",
    },
    editorTextArea: {
      maxHeight: "100%",
      height: ({ maxHeight }) => maxHeight,
      backgroundColor: "white",
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
  })
);

const Editor = ({ editorRef, maxHeight }) => {
  const classes = useStyles({ maxHeight });
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    activeHeading,
    notifications,
    article,
    search,
    isLoading: isLoadingArticle,
  } = useSelector((state) => state.article);
  const { ifGlossary, isLoading, myGlossary, legalese } = useSelector(
    (state) => state.glossary
  );

  useEffect(() => {
    if (activeHeading.id && article.length) {
      createNotifications();
      const activeArticle = article.find(
        (article) => article.heading_id === activeHeading.id
      );
      const paragraph = activeArticle?.paragraph;

      if (paragraph) {
        const blocksFromHtml = convertFromHTML(paragraph.join(""));

        const content = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        );
        onEditorStateChange(EditorState.createWithContent(content));
      } else {
        onEditorStateChange(EditorState.createEmpty());
      }
    }
  }, [activeHeading, article.length]);

  const [editorState, onEditorStateChange] = useState(
    EditorState.createEmpty()
  );

  const highlightStyles = {
    "light-blue": {
      backgroundColor: theme.palette.lightBlue,
    },
    "light-yellow": {
      backgroundColor: theme.palette.lightYellow,
    },
    "light-pink": {
      backgroundColor: theme.palette.lightPink,
    },
    orange: {
      backgroundColor: theme.palette.orange,
    },
    green: {
      color: theme.palette.green,
    },
  };

  const maxLength = 12;

  const initHighlightConfig = {
    rules: [
      {
        content: ifGlossary.map((term) => term.terms),
        style: "light-blue",
        matcher: WordMatcher,
        tooltip: "IF Glossary term",
      },
      {
        content: myGlossary.map((term) => term.terms),
        style: "light-yellow",
        matcher: WordMatcher,
        tooltip: "My glossary term",
      },
      {
        content: legalese.map((term) => term.word),
        style: "light-pink",
        matcher: WordMatcher,
        tooltip: "Legalese term",
      },
      {
        content: search === "" ? [] : [search],
        style: "green",
        matcher: ExactWordMatcher,
      },
      {
        length: maxLength,
        style: "orange",
        matcher: LengthMatcher,
        tooltip: "Long word",
      },
    ],
    styles: highlightStyles,
  };

  const toolbarConfig = {
    options: [
      "inline",
      "fontSize",
      "blockType",
      "textAlign",
      "list",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    blockType: {
      options: ["Normal", "Blockquote"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    history: {
      options: ["undo", "redo"],
    },
    textAlign: {
      options: ["left", "center"],
    },
  };

  const createNotifications = () => {
    const string = editorState
      .getCurrentContent()
      .getPlainText("\u0001")
      .toLowerCase();

    const longWords = string
      .split(" ")
      .filter((word) => word.length > maxLength).length;

    const ifGlossaryFormatted = ifGlossary.map((term) => ({
      ...term,
      type: "if-glossary",
    }));

    const myGlossaryFormatted = myGlossary.map((term) => ({
      ...term,
      type: "my-glossary",
    }));

    const legaleseFormatted = legalese.map((term) => ({
      terms: term.word,
      id: term.id,
      rank: term.rank,
      type: "legalese",
    }));

    const arrayJoined = ifGlossaryFormatted.concat(
      myGlossaryFormatted,
      legaleseFormatted
    );

    const mappedKeywords = arrayJoined
      .map((glossary) => {
        const locations = getLocations(glossary.terms.toLowerCase(), string);
        return {
          ...glossary,
          index: locations.map((location) => ({
            start: location,
            end: location + glossary.terms.length,
          })),
        };
      })
      .filter((word) => word.index.length);

    const notifications = mappedKeywords
      .map((keyword) => ({
        ...keyword,
        index: keyword.index.filter((index) =>
          mappedKeywords.every((term) =>
            term.index.every((indexInside) =>
              index.start === indexInside.start && index.end === indexInside.end
                ? true
                : !(
                    index.start >= indexInside.start &&
                    index.end <= indexInside.end
                  )
            )
          )
        ),
      }))
      .filter((word) => word.index.length);

    dispatch(setNotifications(notifications));
    dispatch(setLongWords(activeHeading.id, longWords));
  };

  useEffect(() => {
    if (activeHeading.id) {
      createNotifications();
      const data = {
        paragraph: getArrayTagsHtmlString(
          draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(
            /\n/g,
            ""
          )
        ),
        if_glossaries: notifications
          .filter((notification) => notification.type === "if-glossary")
          .map((notification) => notification.id),
        my_glossaries: notifications
          .filter((notification) => notification.type === "my-glossary")
          .map((notification) => notification.id),
        legalese: notifications
          .filter((notification) => notification.type === "legalese")
          .map((notification) => notification.id),
      };

      dispatch(updateArticle(id, activeHeading.id, data));
    }
  }, [editorState]);

  return !(
    isLoading[GET_IF_GLOSSARY.REQUEST] ||
    isLoading[GET_LEGALESE.REQUEST] ||
    isLoading[GET_MY_GLOSSARY.REQUEST] ||
    isLoadingArticle[GET_ARTICLE.REQUEST]
  ) ? (
    <EditorWysiwyg
      placeholder="Write or paste (Ctrl + V) your text here."
      spellCheck
      editorRef={(ref) => (editorRef.current = ref)}
      key={editorState + search}
      wrapperClassName={classes.editor}
      editorClassName={classes.editorTextArea}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={toolbarConfig}
      simpleDecorators={[MultiHighlightDecorator(initHighlightConfig)]}
    />
  ) : (
    <CircularProgress
      style={{
        margin: "auto",
      }}
    />
  );
};

export default Editor;
