import React from "react";
import {
  createStyles,
  Divider,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { markFeedback, selectActiveFeedback } from "../store/actions/admin";
import { Check, Block } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    divider: {
      height: ({ isActive }) => (isActive ? "5px" : "1px"),
      backgroundColor: ({ isActive }) =>
        isActive ? theme.palette.primary.main : theme.palette.divider,
    },
    item: {
      cursor: "pointer",
      opacity: ({ isRead }) => (isRead ? 0.5 : 1),
    },
  })
);

const FeedbackItem = ({ item }) => {
  const { activeFeedback } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const isActive = activeFeedback.id === item.id;
  const isRead = item.read;
  const classes = useStyles({ isActive, isRead });

  const selectActive = () => {
    dispatch(selectActiveFeedback({ id: item.id }));
    if (!isRead) {
      dispatch(markFeedback(true, item.id));
    }
  };

  return (
    <>
      <Divider component="li" classes={{ root: classes.divider }} />
      <ListItem className={classes.item} onClick={selectActive}>
        <ListItemText
          primary={item.user}
          secondary={
            <React.Fragment>
              {dayjs(item.date).format("ddd, MM/DD/YYYY")}
              <Typography component="p" variant="body" color="textPrimary">
                {item.message}
              </Typography>
            </React.Fragment>
          }
        />
        {item.status === "DIS" && <Block />}
        {item.status === "CON" && <Check />}
      </ListItem>
    </>
  );
};

export default FeedbackItem;
