import {
  getIfGlossary as getIfGlossaryReq,
  getMyGlossary as getMyGlossaryReq,
  addMyGlossary as addMyGlossaryReq,
  deleteMyGlossary as deleteMyGlossaryReq,
  addCommentIfGlossary as addCommentIfGlossaryReq,
  updateMyGlossary as updateMyGlossaryReq,
  getLegalese as getLegaleseReq,
} from "../../../api";
import { toast } from "material-react-toastify";

const GET_IF_GLOSSARY = {
  REQUEST: "GET_IF_GLOSSARY_REQUEST",
  SUCCESS: "GET_IF_GLOSSARY_SUCCESS",
  ERROR: "GET_IF_GLOSSARY_ERROR",
};

const ADD_COMMENT_IF_GLOSSARY = {
  REQUEST: "ADD_COMMENT_IF_GLOSSARY_REQUEST",
  SUCCESS: "ADD_COMMENT_IF_GLOSSARY_SUCCESS",
  ERROR: "ADD_COMMENT_IF_GLOSSARY_ERROR",
};

const GET_MY_GLOSSARY = {
  REQUEST: "GET_MY_GLOSSARY_REQUEST",
  SUCCESS: "GET_MY_GLOSSARY_SUCCESS",
  ERROR: "GET_MY_GLOSSARY_ERROR",
};

const ADD_MY_GLOSSARY = {
  REQUEST: "ADD_MY_GLOSSARY_REQUEST",
  SUCCESS: "ADD_MY_GLOSSARY_SUCCESS",
  ERROR: "ADD_MY_GLOSSARY_ERROR",
};

const DELETE_MY_GLOSSARY = {
  REQUEST: "DELETE_MY_GLOSSARY_REQUEST",
  SUCCESS: "DELETE_MY_GLOSSARY_SUCCESS",
  ERROR: "DELETE_MY_GLOSSARY_ERROR",
};

const UPDATE_MY_GLOSSARY = {
  REQUEST: "UPDATE_MY_GLOSSARY_REQUEST",
  SUCCESS: "UPDATE_MY_GLOSSARY_SUCCESS",
  ERROR: "UPDATE_MY_GLOSSARY_ERROR",
};

const GET_LEGALESE = {
  REQUEST: "GET_LEGALESE_REQUEST",
  SUCCESS: "GET_LEGALESE_SUCCESS",
  ERROR: "GET_LEGALESE_ERROR",
};

function getLegalese() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await getLegaleseReq()).data;
      dispatch(success({ legalese: res.data }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: GET_LEGALESE.REQUEST };
  }

  function success(payload) {
    return { type: GET_LEGALESE.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_LEGALESE.ERROR, payload };
  }
}

function getIfGlossary() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await getIfGlossaryReq()).data;
      dispatch(success({ ifGlossary: res.data }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: GET_IF_GLOSSARY.REQUEST };
  }

  function success(payload) {
    return { type: GET_IF_GLOSSARY.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_IF_GLOSSARY.ERROR, payload };
  }
}

function getMyGlossary() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await getMyGlossaryReq()).data;
      dispatch(success({ myGlossary: res.data }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: GET_MY_GLOSSARY.REQUEST };
  }

  function success(payload) {
    return { type: GET_MY_GLOSSARY.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_MY_GLOSSARY.ERROR, payload };
  }
}

function addMyGlossary(data) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await addMyGlossaryReq(data)).data;
      dispatch(success({ item: res }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: ADD_MY_GLOSSARY.REQUEST };
  }

  function success(payload) {
    return { type: ADD_MY_GLOSSARY.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: ADD_MY_GLOSSARY.ERROR, payload };
  }
}

function deleteMyGlossary(id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await deleteMyGlossaryReq(id);
      dispatch(success({ id }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: DELETE_MY_GLOSSARY.REQUEST };
  }

  function success(payload) {
    return { type: DELETE_MY_GLOSSARY.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: DELETE_MY_GLOSSARY.ERROR, payload };
  }
}

function addCommentIfGlossary(data, id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await addCommentIfGlossaryReq(data, id);
      dispatch(success());
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: ADD_COMMENT_IF_GLOSSARY.REQUEST };
  }

  function success(payload) {
    return { type: ADD_COMMENT_IF_GLOSSARY.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: ADD_COMMENT_IF_GLOSSARY.ERROR, payload };
  }
}

function updateMyGlossary(data, id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await updateMyGlossaryReq(data, id)).data;
      dispatch(success({ updatedItem: { ...res, id } }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: UPDATE_MY_GLOSSARY.REQUEST };
  }

  function success(payload) {
    return { type: UPDATE_MY_GLOSSARY.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: UPDATE_MY_GLOSSARY.ERROR, payload };
  }
}

export {
  GET_IF_GLOSSARY,
  ADD_COMMENT_IF_GLOSSARY,
  ADD_MY_GLOSSARY,
  DELETE_MY_GLOSSARY,
  GET_MY_GLOSSARY,
  UPDATE_MY_GLOSSARY,
  GET_LEGALESE,
  getIfGlossary,
  getMyGlossary,
  addMyGlossary,
  deleteMyGlossary,
  addCommentIfGlossary,
  updateMyGlossary,
  getLegalese,
};
