import { Typography } from "@material-ui/core";

export default function getEaseDifficulty(value) {
  if (value >= 90 && value <= 100) {
    return "Very Easy";
  }
  if (value >= 80 && value <= 89) {
    return "Easy";
  }
  if (value >= 70 && value <= 79) {
    return "Fairly Easy";
  }
  if (value >= 60 && value <= 69) {
    return "Standard";
  }
  if (value >= 50 && value <= 59) {
    return "Fairly Difficult";
  }
  if (value >= 30 && value <= 49) {
    return "Difficult";
  }
  if (value >= 1 && value <= 29) {
    return "Very Confusing";
  }
  return (
    <Typography variant="caption" color="textSecondary">
      Consider shortening sentences & using clear language
    </Typography>
  );
}
