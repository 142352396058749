import {
  CLEAR_ARTICLE,
  GET_ARTICLE,
  GET_HEADINGS,
  SET_ACTIVE_HEADING,
  SET_LONG_WORDS,
  SET_NOTIFICATIONS,
  SET_SEARCH,
  UPDATE_ARTICLE,
} from "../../actions/article";

const initialState = {
  headings: [],
  error: {},
  name: "",
  isLoading: {},
  activeHeading: {
    id: null,
    name: "Heading 1 ",
    title: "The first text box in template below “Class Action Notice” (Pg.1)",
    sub_title:
      "Who is this notice for? Who is in the class? Write something that will get the attention of the reader.",
  },
  article: [],
  notifications: [],
  reading_ease_total: "\u00A0",
  grade_total: "\u00A0",
  sentence_count_total: "\u00A0",
  search: "",
};

const article = (state = initialState, action) => {
  switch (action.type) {
    case GET_HEADINGS.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_HEADINGS.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_HEADINGS.ERROR]: undefined,
        },
      };

    case GET_HEADINGS.SUCCESS:
      return {
        ...state,
        headings: action.payload.headings,
        isLoading: {
          ...state.isLoading,
          [GET_HEADINGS.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_HEADINGS.ERROR]: undefined,
        },
      };

    case GET_HEADINGS.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_HEADINGS.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_HEADINGS.REQUEST]: false,
        },
      };

    case GET_ARTICLE.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_ARTICLE.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_ARTICLE.ERROR]: undefined,
        },
      };

    case GET_ARTICLE.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: {
          ...state.isLoading,
          [GET_ARTICLE.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_ARTICLE.ERROR]: undefined,
        },
      };

    case GET_ARTICLE.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_ARTICLE.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_ARTICLE.REQUEST]: false,
        },
      };

    case UPDATE_ARTICLE.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [UPDATE_ARTICLE.REQUEST]: true,
        },
      };

    case UPDATE_ARTICLE.SUCCESS:
      return {
        ...state,
        article: !!state.article.find(
          (article) => article.heading_id === action.payload.article.heading_id
        )
          ? state.article.map((article) =>
              article.heading_id === action.payload.article.heading_id
                ? { ...article, ...action.payload.article }
                : article
            )
          : [...state.article, action.payload.article],
        isLoading: {
          ...state.isLoading,
          [UPDATE_ARTICLE.REQUEST]: false,
        },
        error: {
          ...state.error,
          [UPDATE_ARTICLE.ERROR]: undefined,
        },
      };

    case UPDATE_ARTICLE.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [UPDATE_ARTICLE.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [UPDATE_ARTICLE.REQUEST]: false,
        },
      };

    case SET_ACTIVE_HEADING:
      return {
        ...state,
        activeHeading: state.headings.find(
          (heading) => heading.id === action.payload.activeHeading
        ),
      };

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
      };

    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search,
      };

    case CLEAR_ARTICLE:
      return {
        ...state,
        article: [],
        search: "",
      };

    case SET_LONG_WORDS:
      return {
        ...state,
        article: state.article.map((article) =>
          article.heading_id === action.payload.heading_id
            ? { ...article, longWords: action.payload.longWords }
            : article
        ),
      };

    default:
      return state;
  }
};

export default article;
