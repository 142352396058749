import React from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getEaseDifficulty, getGradeLevel } from "../helpers";

const useStyles = makeStyles({
  container: { width: "100%" },
  title: {
    marginBottom: 10,
  },
});

const TextAnalytics = () => {
  const classes = useStyles();
  const { activeHeading, article } = useSelector((state) => state.article);
  const activeArticle = article.find(
    (article) => article.heading_id === activeHeading.id
  );

  const rows = [
    {
      title: "Reading Ease Score",
      score:
        activeArticle?.reading_ease > 0 && activeArticle?.reading_ease <= 100
          ? activeArticle?.reading_ease
          : "",
      label: getEaseDifficulty(activeArticle?.reading_ease),
    },
    {
      title: "Grade Level Score",
      score:
        activeArticle?.grade > 0 && activeArticle?.grade <= 100
          ? activeArticle?.grade
          : "",
      label: getGradeLevel(activeArticle?.grade),
    },
    {
      title: "Number of Sentences",
      score: activeArticle?.sentence_count,
      label: activeArticle?.sentence_count
        ? activeArticle?.sentence_count > 1
          ? "sentences"
          : "sentence"
        : "",
    },
    { title: "Number of Long Words", score: activeArticle?.longWords },
  ];

  return (
    <Box className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Text analytics
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.title}>
                <TableCell>{row.title}</TableCell>
                <TableCell align="center">
                  {row.score}
                  {row.score && <br />}
                  {row.label}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TextAnalytics;
