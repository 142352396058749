import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { GlobalStyles } from "./";
import { CssBaseline } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    lightBlue: "#b3e5fc",
    lightYellow: "#fff176",
    lightPink: "#facfe0",
    orange: "#ffb74d",
    green: "#4caf50",
    amber: "#ffc107",
    indigo: "#3f51b5",
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 700,
      sm: 750,
      md: 960,
      lg: 1600,
      xl: 1920,
    },
  },
});

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
