import instance from "../instance";

export const getIfGlossary = async () => {
  try {
    return await instance.get("/glossary/if");
  } catch (e) {
    throw e.response.data.message;
  }
};

export const addCommentIfGlossary = async (data, id) => {
  try {
    return await instance.post(`/glossary/if/${id}`, data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const getMyGlossary = async () => {
  try {
    return await instance.get("/glossary/my");
  } catch (e) {
    throw e.response.data.message;
  }
};

export const addMyGlossary = async (data) => {
  try {
    return await instance.post("/glossary/my", data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const deleteMyGlossary = async (id) => {
  try {
    return await instance.delete(`/glossary/my/${id}`);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const updateMyGlossary = async (data, id) => {
  try {
    return await instance.put(`/glossary/my/${id}`, data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const getLegalese = async () => {
  try {
    return await instance.get("/legalese");
  } catch (e) {
    throw e.response.data.message;
  }
};
