import { toast } from "material-react-toastify";
import {
  getUserStatistics as getUserStatisticsReq,
  getAppFeedback as getAppFeedbackReq,
  markFeedback as markFeedbackReq,
  setFeedbackStatus as setFeedbackStatusReq,
} from "../../../api";

const GET_USER_STAT = {
  REQUEST: "GET_USER_STAT_REQUEST",
  SUCCESS: "GET_USER_STAT_SUCCESS",
  ERROR: "GET_USER_STAT_ERROR",
};

const GET_APP_FEEDBACK = {
  REQUEST: "GET_APP_FEEDBACK_REQUEST",
  SUCCESS: "GET_APP_FEEDBACK_SUCCESS",
  ERROR: "GET_APP_FEEDBACK_ERROR",
};

const SET_FEEDBACK_STATUS = {
  REQUEST: "SET_FEEDBACK_STATUS_REQUEST",
  SUCCESS: "SET_FEEDBACK_STATUS_SUCCESS",
  ERROR: "SET_FEEDBACK_STATUS_ERROR",
};

const MARK_FEEDBACK = {
  REQUEST: "MARK_FEEDBACK_REQUEST",
  SUCCESS: "MARK_FEEDBACK_SUCCESS",
  ERROR: "MARK_FEEDBACK_ERROR",
};

const SELECT_ACTIVE_FEEDBACK = "SELECT_ACTIVE_FEEDBACK";

function getUserStatistics() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const res = (await getUserStatisticsReq()).data;
      dispatch(success({ userStat: res }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: GET_USER_STAT.REQUEST };
  }

  function success(payload) {
    return { type: GET_USER_STAT.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_USER_STAT.ERROR, payload };
  }
}

function getAppFeedback(page, query) {
  const thunk = async (dispatch) => {
    try {
      dispatch(request());
      const res = (await getAppFeedbackReq(page, query)).data;
      dispatch(
        success({
          feedbackPages: res.page,
          feedbackRows: res.rows,
          feedbacks: res.data,
        })
      );
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  thunk.meta = {
    debounce: {
      time: 200,
      key: "FILTER_FEEDBACK",
    },
  };

  function request() {
    return { type: GET_APP_FEEDBACK.REQUEST };
  }

  function success(payload) {
    return { type: GET_APP_FEEDBACK.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_APP_FEEDBACK.ERROR, payload };
  }

  return thunk;
}

function markFeedback(read, id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await markFeedbackReq(read, id);
      dispatch(success({ read, id }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: MARK_FEEDBACK.REQUEST };
  }

  function success(payload) {
    return { type: MARK_FEEDBACK.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: MARK_FEEDBACK.ERROR, payload };
  }
}

function setFeedbackStatus(status, id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await setFeedbackStatusReq(status, id);
      dispatch(success({ id, status }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: SET_FEEDBACK_STATUS.REQUEST };
  }

  function success(payload) {
    return { type: SET_FEEDBACK_STATUS.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: SET_FEEDBACK_STATUS.ERROR, payload };
  }
}

function selectActiveFeedback(payload) {
  return {
    type: SELECT_ACTIVE_FEEDBACK,
    payload,
  };
}

export {
  getUserStatistics,
  GET_USER_STAT,
  GET_APP_FEEDBACK,
  getAppFeedback,
  SELECT_ACTIVE_FEEDBACK,
  selectActiveFeedback,
  setFeedbackStatus,
  markFeedback,
  MARK_FEEDBACK,
  SET_FEEDBACK_STATUS,
};
