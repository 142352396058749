import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AttachFile } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sendAppFeedback } from "../store/actions/user";
import { convertBase64 } from "../helpers";

const useStyles = makeStyles({
  textField: {
    marginTop: 20,
    height: "100%",
    "& > .MuiOutlinedInput-multiline": {
      backgroundColor: "#fff",
      height: "100%",
    },
  },
  btn: {
    marginTop: 10,
    alignSelf: "flex-start",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  submitBtn: {
    alignSelf: "flex-end",
  },
  label: {
    wordBreak: "break-word",
  },
});

const HomeFeedback = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [label, setLabel] = useState("Browse attachment");
  const onSubmit = async (data) => {
    let file = null;
    if (data.attachments.length) {
      file = await convertBase64(data.attachments[0]);
    }
    dispatch(sendAppFeedback({ ...data, ...(file && { attachments: file }) }));
  };
  const onFileSelected = (e) => {
    setLabel(e.target.files[0].name);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.wrapper}>
      <TextField
        name="message"
        label="Feedback"
        multiline
        rows={8}
        variant="outlined"
        className={classes.textField}
        fullWidth
        inputRef={register({
          required: {
            value: true,
            message: "This field is required",
          },
        })}
        error={errors.feedback}
        helperText={errors.feedback?.message}
      />
      <Button
        component="label"
        className={classes.btn}
        startIcon={<AttachFile />}
        classes={{
          label: classes.label,
        }}
      >
        {label}
        <input
          type="file"
          hidden
          name="attachments"
          ref={register}
          onChange={onFileSelected}
        />
      </Button>
      <Button variant="contained" className={classes.submitBtn} type="submit">
        Submit your feedback
      </Button>
    </form>
  );
};

export default HomeFeedback;
