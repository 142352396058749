import {
  GET_APP_FEEDBACK,
  GET_USER_STAT,
  MARK_FEEDBACK,
  SELECT_ACTIVE_FEEDBACK,
  SET_FEEDBACK_STATUS,
} from "../../actions/admin";

const initialState = {
  userStat: {},
  feedbackPages: 0,
  feedbackRows: 0,
  feedbacks: [],
  activeFeedback: { id: null },
  error: {},
  isLoading: {},
};

const article = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_STAT.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_USER_STAT.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_USER_STAT.ERROR]: undefined,
        },
      };

    case GET_USER_STAT.SUCCESS:
      return {
        ...state,
        userStat: action.payload.userStat,
        isLoading: {
          ...state.isLoading,
          [GET_USER_STAT.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_USER_STAT.ERROR]: undefined,
        },
      };

    case GET_USER_STAT.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_USER_STAT.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_USER_STAT.REQUEST]: false,
        },
      };

    case GET_APP_FEEDBACK.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_APP_FEEDBACK.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_APP_FEEDBACK.ERROR]: undefined,
        },
      };

    case GET_APP_FEEDBACK.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: {
          ...state.isLoading,
          [GET_APP_FEEDBACK.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_APP_FEEDBACK.ERROR]: undefined,
        },
      };

    case GET_APP_FEEDBACK.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_APP_FEEDBACK.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_APP_FEEDBACK.REQUEST]: false,
        },
      };

    case MARK_FEEDBACK.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [MARK_FEEDBACK.REQUEST]: true,
        },
        error: {
          ...state.error,
          [MARK_FEEDBACK.ERROR]: undefined,
        },
      };

    case MARK_FEEDBACK.SUCCESS:
      return {
        ...state,
        feedbacks: state.feedbacks.map((item) =>
          item.id === action.payload.id
            ? { ...item, read: action.payload.read }
            : item
        ),
        isLoading: {
          ...state.isLoading,
          [MARK_FEEDBACK.REQUEST]: false,
        },
        error: {
          ...state.error,
          [MARK_FEEDBACK.ERROR]: undefined,
        },
      };

    case MARK_FEEDBACK.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [MARK_FEEDBACK.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [MARK_FEEDBACK.REQUEST]: false,
        },
      };

    case SET_FEEDBACK_STATUS.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [SET_FEEDBACK_STATUS.REQUEST]: true,
        },
        error: {
          ...state.error,
          [SET_FEEDBACK_STATUS.ERROR]: undefined,
        },
      };

    case SET_FEEDBACK_STATUS.SUCCESS:
      return {
        ...state,
        feedbacks: state.feedbacks.map((item) =>
          item.id === action.payload.id
            ? { ...item, status: action.payload.status }
            : item
        ),
        isLoading: {
          ...state.isLoading,
          [SET_FEEDBACK_STATUS.REQUEST]: false,
        },
        error: {
          ...state.error,
          [SET_FEEDBACK_STATUS.ERROR]: undefined,
        },
      };

    case SET_FEEDBACK_STATUS.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [SET_FEEDBACK_STATUS.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [SET_FEEDBACK_STATUS.REQUEST]: false,
        },
      };

    case SELECT_ACTIVE_FEEDBACK:
      return {
        ...state,
        activeFeedback: state.feedbacks.find(
          (item) => item.id === action.payload.id
        ),
      };

    default:
      return state;
  }
};

export default article;
