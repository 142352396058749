import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";
import { getMonthName } from "../helpers";

const Chart = ({ name, data, className, color }) => {
  const convertToChartData = (array) => {
    return array?.map((item) => ({
      count: item.Count,
      name: `${getMonthName(item.Month)}-${item.Year}`,
    }));
  };
  return (
    <ResponsiveContainer height={330} className={className}>
      <BarChart
        width={500}
        height={300}
        data={convertToChartData(data)}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
        <YAxis />
        <Tooltip />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar
          name={name}
          dataKey="count"
          fill={color}
          background={{ fill: "#eee" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
