import React, { useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/actions/user";
import { useHistory } from "react-router-dom";
import { SIGN_IN } from "../store/actions/user";
import { Loading } from "../components";
import sharkLogo from "../assets/shark2.jpg";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
  title: {
    marginBottom: 20,
  },
  signUpBtn: {
    marginTop: 10,
  },
});

const SignIn = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    dispatch(login(data));
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      if (user.isAdmin) {
        history.push("/dashboard-admin");
      } else {
        history.push("/home");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isAuthenticated]);

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <Container className={classes.container}>
        {user.isLoading[SIGN_IN.REQUEST] && <Loading />}
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid
            item
            container
            md={6}
            justify="center"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h4"
                align="center"
                className={classes.title}
              >
                Login
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Email address"
                fullWidth={true}
                name="email"
                type="email"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                error={errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Password"
                fullWidth={true}
                type="password"
                name="password"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                error={errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={9} justify="center" container>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Login
              </Button>
              <Button
                fullWidth
                className={classes.signUpBtn}
                color="primary"
                onClick={() => history.push("/signup")}
              >
                Register
              </Button>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <img src={sharkLogo} alt="shark-logo" />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SignIn;
