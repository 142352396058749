import { LinearProgress, withStyles } from "@material-ui/core";

const Loading = withStyles({
  root: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    zIndex: 1500,
  },
})(({ classes }) => <LinearProgress className={classes.root} />);

export default Loading;
