import React from "react";
import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    tableContainer: {
      maxWidth: 500,
      marginLeft: 174,
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
    },
    tableTitleWrapper: {
      backgroundColor: ({ color }) =>
        theme.palette.grey[color === "dark" ? "600" : "200"],
      padding: 8,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    tableTitle: {
      color: ({ color }) =>
        color === "dark" ? "white" : theme.palette.grey["700"],
      textAlign: "center",
      fontWeight: "bold",
    },
    table: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
    container: {
      marginTop: 20,
    },
    noMargin: {
      margin: 0,
      textAlign: "right",
      marginRight: 30,
    },
    cellTitle: {
      width: "80%",
    },
    cellValue: {
      width: "20%",
    },
  })
);

const AdminTable = ({ data, title, color = "dark" }) => {
  const classes = useStyles({ color });
  return (
    <TableContainer component={Paper} className={classes.container}>
      <Paper variant="outlined" className={classes.tableTitleWrapper}>
        <Typography className={classes.tableTitle}>{title}</Typography>
      </Paper>
      <Table className={classes.table}>
        <TableBody>
          {data.map((row) => {
            const newText = row.title.split("\n").map((item, i) => (
              <p className={classes.noMargin} key={i}>
                {item}
              </p>
            ));
            return (
              <TableRow key={row.title}>
                <TableCell className={classes.cellTitle}>{newText}</TableCell>
                <TableCell className={classes.cellValue}>
                  {row.value || ""}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminTable;
