import {
  SIGN_UP,
  SIGN_IN,
  RESET_PWD,
  SIGN_OUT,
  RESET_PWD_CONFIRM,
  CHECK_USERNAME,
  GET_PROFILE,
  UPDATE_PROFILE,
  CLEAR,
  SEND_APP_FEEDBACK,
} from "../../actions/user";
import dayjs from "dayjs";

const initialState = {
  isAdmin: localStorage.is_admin === "true",
  isAuthenticated:
    localStorage.token != null && dayjs(localStorage.expire).isAfter(dayjs()),
  isLoading: {},
  error: {},
  profile: {
    first_name: "",
    last_name: "",
    phone: "",
    company_name: "",
    user_name: "",
    email: "",
  },
  isRegistered: false,
  completedUserUpdate: false,
  resetToken: undefined,
  confirmedReset: false,
  is_profile_completed: localStorage.is_profile_completed,
  is_admin: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [SIGN_IN.REQUEST]: true,
        },
        error: {
          ...state.error,
          [SIGN_IN.ERROR]: undefined,
        },
      };

    case SIGN_IN.SUCCESS:
      return {
        ...state,
        profile: {
          email: action.payload.email,
        },
        is_profile_completed: action.payload.is_profile_completed,
        isAdmin: action.payload.is_admin,
        isAuthenticated: true,
        isLoading: {
          ...state.isLoading,
          [SIGN_IN.REQUEST]: false,
        },
        error: {
          ...state.error,
          [SIGN_IN.ERROR]: undefined,
        },
      };

    case SIGN_IN.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [SIGN_IN.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [SIGN_IN.REQUEST]: false,
        },
      };

    case SIGN_UP.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [SIGN_UP.REQUEST]: true,
        },
        error: {
          ...state.error,
          [SIGN_UP.ERROR]: undefined,
        },
      };

    case SIGN_UP.SUCCESS:
      return {
        ...state,
        isRegistered: true,
        profile: {
          email: action.payload.email,
        },
        isLoading: {
          ...state.isLoading,
          [SIGN_UP.REQUEST]: false,
        },
        error: {
          ...state.error,
          [SIGN_UP.ERROR]: undefined,
        },
      };

    case SIGN_UP.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [SIGN_UP.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [SIGN_UP.REQUEST]: false,
        },
      };

    case RESET_PWD.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [RESET_PWD.REQUEST]: true,
        },
        error: {
          ...state.error,
          [RESET_PWD.ERROR]: undefined,
        },
      };

    case RESET_PWD.SUCCESS:
      return {
        ...state,
        resetToken: action.payload.resetToken,
        isLoading: {
          ...state.isLoading,
          [RESET_PWD.REQUEST]: false,
        },
        error: {
          ...state.error,
          [RESET_PWD.ERROR]: undefined,
        },
      };

    case RESET_PWD.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [RESET_PWD.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [RESET_PWD.REQUEST]: false,
        },
      };

    case RESET_PWD_CONFIRM.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [RESET_PWD_CONFIRM.REQUEST]: true,
        },
        error: {
          ...state.error,
          [RESET_PWD_CONFIRM.ERROR]: undefined,
        },
      };

    case RESET_PWD_CONFIRM.SUCCESS:
      return {
        ...state,
        confirmedReset: true,
        isLoading: {
          ...state.isLoading,
          [RESET_PWD_CONFIRM.REQUEST]: false,
        },
        error: {
          ...state.error,
          [RESET_PWD_CONFIRM.ERROR]: undefined,
        },
      };

    case RESET_PWD_CONFIRM.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [RESET_PWD_CONFIRM.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [RESET_PWD_CONFIRM.REQUEST]: false,
        },
      };

    case CHECK_USERNAME.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [CHECK_USERNAME.REQUEST]: true,
        },
      };

    case CHECK_USERNAME.SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [CHECK_USERNAME.REQUEST]: false,
        },
        error: {
          ...state.error,
          [CHECK_USERNAME.ERROR]: undefined,
        },
      };

    case CHECK_USERNAME.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [CHECK_USERNAME.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [CHECK_USERNAME.REQUEST]: false,
        },
      };

    case GET_PROFILE.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_PROFILE.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_PROFILE.ERROR]: undefined,
        },
      };

    case GET_PROFILE.SUCCESS:
      return {
        ...state,
        profile: {
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          user_name: action.payload.user_name,
          email: action.payload.email,
          company_name: action.payload.company_name,
          phone: action.payload.phone,
        },
        is_profile_completed: action.payload.is_profile_completed,
        is_admin: action.payload.is_admin,
        isLoading: {
          ...state.isLoading,
          [GET_PROFILE.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_PROFILE.ERROR]: undefined,
        },
      };

    case GET_PROFILE.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_PROFILE.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_PROFILE.REQUEST]: false,
        },
      };

    case UPDATE_PROFILE.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [UPDATE_PROFILE.REQUEST]: true,
        },
        error: {
          ...state.error,
          [UPDATE_PROFILE.ERROR]: undefined,
        },
      };

    case UPDATE_PROFILE.SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload.profile },
        is_profile_completed: true,
        completedUserUpdate: true,
        isLoading: {
          ...state.isLoading,
          [UPDATE_PROFILE.REQUEST]: false,
        },
        error: {
          ...state.error,
          [UPDATE_PROFILE.ERROR]: undefined,
        },
      };

    case UPDATE_PROFILE.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [UPDATE_PROFILE.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [UPDATE_PROFILE.REQUEST]: false,
        },
      };

    case SEND_APP_FEEDBACK.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [SEND_APP_FEEDBACK.REQUEST]: true,
        },
        error: {
          ...state.error,
          [SEND_APP_FEEDBACK.ERROR]: undefined,
        },
      };

    case SEND_APP_FEEDBACK.SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [SEND_APP_FEEDBACK.REQUEST]: false,
        },
        error: {
          ...state.error,
          [SEND_APP_FEEDBACK.ERROR]: undefined,
        },
      };

    case UPDATE_PROFILE.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [SEND_APP_FEEDBACK.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [SEND_APP_FEEDBACK.REQUEST]: false,
        },
      };

    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
      };

    case CLEAR:
      return {
        ...state,
        registered: false,
        resetToken: undefined,
        confirmedReset: false,
        completedUserUpdate: false,
      };

    default:
      return state;
  }
};

export default user;
