import { Button } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  menuButton: {
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    borderBottom: ({ to, location }) =>
      to === location ? "5px solid red" : null,
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
    paddingBottom: ({ to, location }) => (to === location ? "1px" : null),
  },
});

const HeaderButton = (props) => {
  const location = useLocation();
  const classes = useStyles({ location: location.pathname, ...props });
  return <Button className={classes.menuButton} {...props} />;
};

export default HeaderButton;
