import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Box,
  IconButton,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import FileDownload from "js-file-download";

import {
  CheckCircleRounded,
  CancelRounded,
  InsertDriveFileRounded,
} from "@material-ui/icons";

import { useDispatch, useSelector } from "react-redux";
import { downloadFeedbackAttachments } from "../api";
import { setFeedbackStatus } from "../store/actions/admin";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  name: {
    fontWeight: "bold",
  },
  label: {
    alignSelf: "flex-end",
    fontStyle: "italic",
    marginTop: "12px",
    marginBottom: "12px",
  },
  textBody: {
    marginTop: "12px",
  },
  circularLoading: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 12,
    marginRight: 12,
  },
});

const FeedbackCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { activeFeedback } = useSelector((state) => state.admin);

  const downloadAttachment = async () => {
    setLoading(true);
    const response = await downloadFeedbackAttachments(
      activeFeedback.attachments
    );
    FileDownload(response.data, activeFeedback.attachments);
    setLoading(false);
  };

  const setStatus = (status) => {
    dispatch(setFeedbackStatus(status, activeFeedback.id));
  };

  return (
    activeFeedback.id && (
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.name}>
              {activeFeedback.user}
            </Typography>
            <Box>
              <IconButton onClick={() => setStatus("CON")}>
                <CheckCircleRounded />
              </IconButton>
              <IconButton onClick={() => setStatus("DIS")}>
                <CancelRounded />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          {activeFeedback.attachments && (
            <>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Typography className={classes.label} color="textSecondary">
                  Attachments
                </Typography>
                {loading ? (
                  <CircularProgress
                    size={24}
                    className={classes.circularLoading}
                  />
                ) : (
                  <IconButton onClick={downloadAttachment}>
                    <InsertDriveFileRounded />
                  </IconButton>
                )}
              </Box>
              <Divider variant="inline" />
            </>
          )}
          <Typography
            className={classes.textBody}
            variant="body2"
            component="p"
          >
            {activeFeedback.message}
          </Typography>
        </CardContent>
      </Card>
    )
  );
};

export default FeedbackCard;
