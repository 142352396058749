import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, createStyles } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { useSelector, useDispatch } from "react-redux";
import { setActiveHeading } from "../store/actions/article";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const HeadingListItem = ({ item }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeHeading } = useSelector((state) => state.article);
  const handleListItemClick = (event, id) => {
    dispatch(setActiveHeading(id));
  };

  return (
    <ListItem
      button
      className={classes.nested}
      selected={activeHeading.id === item.id}
      onClick={(event) => handleListItemClick(event, item.id)}
      key={item.id}
    >
      <ListItemText primary={item.name} />
    </ListItem>
  );
};

export default HeadingListItem;
