import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import createDebounce from "redux-debounced";
import { user, glossary, draft, article, admin } from "./reducers";

const persistConfig = {
  key: "daily-harvest-web-persist-store",
  storage,
  whitelist: [],
};

const combinedReducer = combineReducers({
  user,
  glossary,
  draft,
  article,
  admin,
});
const composeEnhancers =
  window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
const enhancer = composeEnhancers(applyMiddleware(createDebounce(), thunk));
const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);
