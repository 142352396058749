import {
  forgotPasswordConfirm,
  forgotPasswordReq,
  signIn,
  signUp,
  updateUser,
  usernameAvailable,
  getProfile as getProfileReq,
  sendAppFeedback as sendAppFeedbackReq,
} from "../../../api";
import { toast } from "material-react-toastify";

function login(data) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const result = (await signIn(data)).data;
      localStorage.setItem("is_admin", result.is_admin);
      localStorage.setItem("token", result.Authorization);
      localStorage.setItem("expire", result.expity);
      localStorage.setItem("is_profile_completed", result.is_profile_completed);
      dispatch(success(result));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: SIGN_IN.REQUEST };
  }

  function success(payload) {
    return { type: SIGN_IN.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: SIGN_IN.ERROR, payload };
  }
}

function register(data) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await signUp(data);
      dispatch(success({ email: data.email }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: SIGN_UP.REQUEST };
  }

  function success(payload) {
    return { type: SIGN_UP.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: SIGN_UP.ERROR, payload };
  }
}

function resetPasswordRequest(data) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = (await forgotPasswordReq(data)).data;
      dispatch(success({ resetToken: response.uuid }));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: RESET_PWD.REQUEST };
  }

  function success(payload) {
    return { type: RESET_PWD.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: RESET_PWD.ERROR, payload };
  }
}

function resetPasswordConfirm(data, uuid) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await forgotPasswordConfirm(data, uuid);
      toast.success("Success! Please login with new password");
      dispatch(success());
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: RESET_PWD_CONFIRM.REQUEST };
  }

  function success() {
    return { type: RESET_PWD_CONFIRM.SUCCESS };
  }

  function failure(payload) {
    return { type: RESET_PWD_CONFIRM.ERROR, payload };
  }
}

function checkUsername(name) {
  const thunk = async (dispatch) => {
    try {
      dispatch(request());
      await usernameAvailable(name);
      dispatch(success());
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  thunk.meta = {
    debounce: {
      time: 500,
      key: "CHECK_USERNAME",
    },
  };

  function request() {
    return { type: CHECK_USERNAME.REQUEST };
  }

  function success() {
    return { type: CHECK_USERNAME.SUCCESS };
  }

  function failure(payload) {
    return { type: CHECK_USERNAME.ERROR, payload };
  }

  return thunk;
}

function getProfile() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = (await getProfileReq()).data;
      dispatch(success(response));
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: GET_PROFILE.REQUEST };
  }

  function success(payload) {
    return { type: GET_PROFILE.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: GET_PROFILE.ERROR, payload };
  }
}

function updateProfile(data, isEmailUpdated, oldEmail) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const result = (await updateUser(data)).data;
      localStorage.setItem("is_profile_completed", result.is_profile_completed);
      if (isEmailUpdated) {
        dispatch(success());
        toast.success(`Please go to your ${oldEmail} to confirm email change.`);
      } else {
        dispatch(success({ profile: data }));
      }
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: UPDATE_PROFILE.REQUEST };
  }

  function success(payload) {
    return { type: UPDATE_PROFILE.SUCCESS, payload };
  }

  function failure(payload) {
    return { type: UPDATE_PROFILE.ERROR, payload };
  }
}

function sendAppFeedback(data) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await sendAppFeedbackReq(data);
      toast.success("Your feedback was successfully sent!");
      dispatch(success());
    } catch (e) {
      dispatch(failure(e));
      toast.error(e);
    }
  };

  function request() {
    return { type: SEND_APP_FEEDBACK.REQUEST };
  }

  function success() {
    return { type: SEND_APP_FEEDBACK.SUCCESS };
  }

  function failure(payload) {
    return { type: SEND_APP_FEEDBACK.ERROR, payload };
  }
}

function signOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("expire");
  localStorage.removeItem("is_profile_completed");
  localStorage.removeItem("is_admin");
  return { type: SIGN_OUT };
}

function clear() {
  return { type: CLEAR };
}

const CLEAR = "CLEAR";

const SEND_APP_FEEDBACK = {
  REQUEST: "SEND_APP_FEEDBACK_REQUEST",
  SUCCESS: "SEND_APP_FEEDBACK_SUCCESS",
  ERROR: "SEND_APP_FEEDBACK_ERROR",
};

const SIGN_UP = {
  REQUEST: "SIGN_UP_REQUEST",
  SUCCESS: "SIGN_UP_SUCCESS",
  ERROR: "SIGN_UP_ERROR",
};

const SIGN_IN = {
  REQUEST: "SIGN_IN_REQUEST",
  SUCCESS: "SIGN_IN_SUCCESS",
  ERROR: "SIGN_IN_ERROR",
};

const RESET_PWD = {
  REQUEST: "RESET_PWD_REQUEST",
  SUCCESS: "RESET_PWD_SUCCESS",
  ERROR: "RESET_PWD_ERROR",
};

const RESET_PWD_CONFIRM = {
  REQUEST: "RESET_PWD_CONFIRM_REQUEST",
  SUCCESS: "RESET_PWD_CONFIRM_SUCCESS",
  ERROR: "RESET_PWD_CONFIRM_ERROR",
};

const UPDATE_PROFILE = {
  REQUEST: "UPDATE_PROFILE_REQUEST",
  SUCCESS: "UPDATE_PROFILE_SUCCESS",
  ERROR: "UPDATE_PROFILE_ERROR",
};

const CHECK_USERNAME = {
  REQUEST: "CHECK_USERNAME_REQUEST",
  SUCCESS: "CHECK_USERNAME_SUCCESS",
  ERROR: "CHECK_USERNAME_ERROR",
};

const GET_PROFILE = {
  REQUEST: "GET_PROFILE_REQUEST",
  SUCCESS: "GET_PROFILE_SUCCESS",
  ERROR: "GET_PROFILE_ERROR",
};

const SIGN_OUT = "SIGN_OUT";

export {
  SIGN_UP,
  SIGN_IN,
  RESET_PWD,
  SIGN_OUT,
  RESET_PWD_CONFIRM,
  UPDATE_PROFILE,
  CHECK_USERNAME,
  GET_PROFILE,
  CLEAR,
  SEND_APP_FEEDBACK,
  login,
  register,
  resetPasswordRequest,
  resetPasswordConfirm,
  checkUsername,
  getProfile,
  signOut,
  clear,
  updateProfile,
  sendAppFeedback,
};
