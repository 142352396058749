import React, { useEffect } from "react";
import {
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Divider,
  Typography,
  createStyles,
  CircularProgress,
} from "@material-ui/core";

import { Close, Edit } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DateInput } from "./index";
import { useHistory } from "react-router-dom";
import {
  addDraft,
  clearActiveDraft,
  GET_DRAFT,
  getDraft,
  updateDraft,
} from "../store/actions/draft";

const useStyles = makeStyles((theme) =>
  createStyles({
    btn: {
      padding: 20,
    },
    icon: {
      marginLeft: 10,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      marginBottom: 5,
    },
    btnContainer: {
      padding: "2px 10px",
      justifyContent: "flex-start",
    },
    modal: {
      height: "100%",
      justifyContent: ({ isLoading }) =>
        isLoading[GET_DRAFT.REQUEST] ? "center" : null,
      alignItems: ({ isLoading }) =>
        isLoading[GET_DRAFT.REQUEST] ? "center" : null,
    },
  })
);

export default function DraftModal({ type, id }) {
  const dispatch = useDispatch();
  const { activeDraft } = useSelector((state) => state.draft);
  const { isLoading } = useSelector((state) => state.draft);
  const classes = useStyles({ isLoading });
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const { register, handleSubmit, errors, control, reset } = useForm({
    defaultValues: activeDraft,
  });

  const editArticle = () => {
    history.push(`/draft/${activeDraft.id}`);
  };

  const onSubmit = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => !!value)
    );
    if (type === "edit") {
      dispatch(updateDraft(filteredData, id));
    } else {
      dispatch(addDraft(filteredData));
    }
    handleClose();
  };

  const handleClickOpen = () => {
    dispatch(getDraft(id));
    setOpen(true);
  };

  const handleClose = () => {
    setTimeout(() => {
      dispatch(clearActiveDraft());
    }, 200);
    setOpen(false);
  };

  useEffect(() => {
    reset(activeDraft);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDraft, isLoading]);
  return (
    <div>
      {type === "edit" ? (
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          classes={{
            containedSizeSmall: classes.btnContainer,
          }}
          startIcon={<Edit />}
          size="small"
          fullWidth
          onClick={handleClickOpen}
        >
          Edit
        </Button>
      ) : (
        <Button onClick={handleClickOpen} variant="contained">
          Create new draft
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        classes={{
          paper: classes.modal,
        }}
        maxWidth="md"
      >
        {isLoading[GET_DRAFT.REQUEST] ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="form-dialog-title">
              <Typography variant="h6">
                {type === "edit"
                  ? `Edit ${activeDraft?.case_name}`
                  : "New draft creation"}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.closeButton}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Judge's name"
                    type="text"
                    name="judge_name"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Court name"
                    name="court_name"
                    type="text"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Court Address"
                    type="text"
                    name="court_address"
                    multiline
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Case name"
                    name="case_name"
                    type="text"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Case #"
                    type="text"
                    name="case_number"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Divider />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Plaintiff’s Name(s)"
                    name="plaintiff_name"
                    type="text"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Defendant’s Name(s)"
                    type="text"
                    name="defendant_name"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DateInput
                    control={control}
                    margin="dense"
                    label="Date the Case was Filed"
                    name="date_the_case_was_filed"
                    fullWidth
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DateInput
                    control={control}
                    margin="dense"
                    label="Date Class Certified"
                    name="date_class_certified"
                    fullWidth
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DateInput
                    control={control}
                    margin="dense"
                    label="Deadline to object"
                    name="deadline_to_object"
                    fullWidth
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Case Website"
                    type="text"
                    name="case_website"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Lawyers' fees URL"
                    name="lawyers_fees_url"
                    type="text"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Objection contact address"
                    type="text"
                    name="objection_contact_address"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Class Counsel Name"
                    name="class_counsel_name"
                    type="text"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Class Counsel Email"
                    type="text"
                    name="class_counsel_email"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Class Counsel Mailing Address"
                    name="class_counsel_address"
                    multiline
                    type="text"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DateInput
                    control={control}
                    margin="dense"
                    label="Settlement approval hearing date"
                    name="settlement_approval_hearing_date"
                    type="text"
                    fullWidth
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Settlement Administrator"
                    name="settlement_administrator"
                    multiline
                    type="text"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Defendant’s counsel contact"
                    type="text"
                    name="defendant_counsel_contact"
                    fullWidth
                    inputRef={register}
                    multiline
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    margin="dense"
                    label="Defendant’s Contact"
                    name="defendant_contact"
                    type="text"
                    fullWidth
                    inputRef={register}
                    InputLabelProps={{
                      shrink: type === "edit" ? true : undefined,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button fullWidth color="primary" onClick={handleClose}>
                Cancel
              </Button>
              {type === "edit" && (
                <Button fullWidth color="primary" onClick={editArticle}>
                  Edit Draft
                </Button>
              )}
              <Button fullWidth color="primary" type="submit">
                {type === "create" ? "Create Draft" : "Save"}
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </div>
  );
}
