import React, { useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clear, register as signup, SIGN_UP } from "../store/actions/user";
import { useHistory } from "react-router-dom";
import { Loading } from "../components";
import sharkLogo from "../assets/shark1.jpg";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  title: {
    marginBottom: 20,
  },
  form: {
    [theme.breakpoints.down("sm")]: {
      order: 0,
    },
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  signInBtn: {
    marginTop: 10,
  },
}));

const SignUp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { register, handleSubmit, errors, watch } = useForm();

  useEffect(() => {
    if (user.isRegistered) {
      history.push("/confirm/");
    }

    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isRegistered]);

  const onSubmit = (data) => {
    dispatch(signup(data));
  };

  const password = watch("password", "");

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <Container className={classes.container}>
        {user.isLoading[SIGN_UP.REQUEST] && <Loading />}
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item md={6} className={classes.image}>
            <img src={sharkLogo} alt="shark-logo" />
          </Grid>
          <Grid
            container
            item
            md={6}
            spacing={3}
            justify="center"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h4"
                align="center"
                className={classes.title}
              >
                Let's get started
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="First name"
                fullWidth={true}
                name="first_name"
                type="firstName"
                inputRef={register({ required: "This is required" })}
                error={errors.first_name}
                helperText={errors.first_name?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Last name"
                fullWidth={true}
                name="last_name"
                type="lastName"
                inputRef={register({ required: "This is required" })}
                error={errors.last_name}
                helperText={errors.last_name?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Email address"
                fullWidth={true}
                name="email"
                type="email"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  minLength: {
                    value: 4,
                    message: "Email should be at least 4 symbols",
                  },
                  maxLength: {
                    value: 254,
                    message: "Email should not be longer then 254 symbols",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Email should have form user@email.com",
                  },
                })}
                error={errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Password"
                fullWidth={true}
                type="password"
                name="password"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Password should have at least 8 symbols",
                  },
                  maxLength: {
                    value: 24,
                    message: "Password should not be longer then 24 symbols",
                  },
                  pattern: {
                    // eslint-disable-next-line
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
                    message:
                      "Passwords must contain at least one lower case letter, at least one upper case letter, have a least one 0-9 digit, and have at least one special character",
                  },
                })}
                error={errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Confirm password"
                fullWidth={true}
                type="password"
                name="confirmPassword"
                inputRef={register({
                  validate: (value) =>
                    value === password || "The passwords do not match",
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                error={errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
            </Grid>
            <Grid item xs={9}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Register
              </Button>
              <Button
                fullWidth
                className={classes.signInBtn}
                color="primary"
                onClick={() => history.push("/signin")}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SignUp;
