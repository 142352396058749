import React, { useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clear, resetPasswordConfirm } from "../store/actions/user";
import { useHistory, useParams } from "react-router-dom";
import { RESET_PWD_CONFIRM } from "../store/actions/user";
import { Loading } from "../components";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
  title: {
    marginBottom: 20,
  },
  input: {
    marginBottom: 50,
  },
});

const ForgotPasswordConfirm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, watch } = useForm();
  const onSubmit = (data) => {
    dispatch(resetPasswordConfirm(data, token));
  };

  useEffect(() => {
    if (user.confirmedReset) {
      history.push(`/signin`);
    }

    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.confirmedReset]);

  const password = watch("password", "");

  return (
    <>
      <Helmet>
        <title>Forgot password</title>
      </Helmet>
      <Container className={classes.container}>
        {user.isLoading[RESET_PWD_CONFIRM.REQUEST] && <Loading />}
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid
            container
            md={6}
            spacing={3}
            justify="center"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h4"
                align="center"
                className={classes.title}
              >
                Confirm password reset
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.input}
                placeholder="Pin from email"
                fullWidth={true}
                name="pin"
                type="pin"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  pattern: {
                    // eslint-disable-next-line
                    value: /^[0-9]{6}$/,
                    message: "Pin should be 6 numbers code",
                  },
                })}
                error={errors.pin}
                helperText={errors.pin?.message}
              />
              <TextField
                className={classes.input}
                placeholder="New password"
                fullWidth={true}
                name="password"
                type="password"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Password should have at least 8 symbols",
                  },
                  maxLength: {
                    value: 24,
                    message: "Password should not be longer then 24 symbols",
                  },
                  pattern: {
                    // eslint-disable-next-line
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
                    message:
                      "Passwords must contain at least one lower case letter, at least one upper case letter, have a least one 0-9 digit, and have at least one special character",
                  },
                })}
                error={errors.password}
                helperText={errors.password?.message}
              />
              <TextField
                placeholder="Confirm password"
                fullWidth={true}
                type="password"
                name="confirmPassword"
                inputRef={register({
                  validate: (value) =>
                    value === password || "The passwords do not match",
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                error={errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
            </Grid>
            <Grid item xs={9}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ForgotPasswordConfirm;
