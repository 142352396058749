import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  createStyles,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import { InsertDriveFile } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../layout";

import { Helmet } from "react-helmet";
import { DataGrid } from "@material-ui/data-grid";
import { getReceiptPdf, getDraftStatistics } from "../api";
import dayjs from "dayjs";
import { b64toBlob } from "../helpers";
import FileDownload from "js-file-download";
import { toast } from "material-react-toastify";
import { Loading } from "../components";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: "100%",
      paddingTop: 94,
      paddingBottom: 50,
    },
    gridContainer: {
      backgroundColor: theme.palette.grey["200"],
    },
    headerTitle: {
      backgroundColor: theme.palette.grey["500"],
      padding: theme.spacing(2),
    },
    cardsGrid: {
      padding: "15px",
      [theme.breakpoints.down("xs")]: {
        padding: "15px 0 0 0",
      },
      "& .MuiDataGrid-row, & .MuiDataGrid-root & .MuiDataGrid-cell, & .rendering-zone":
        {
          "max-height": "52px !important",
        },
    },
    contentWrapper: {
      backgroundColor: "white",
    },
    title: {
      color: "white",
    },
  })
);

const Receipts = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [rows, setRows] = useState([]);
  const rowsNumber = useRef(0);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = (await getDraftStatistics(page)).data;
      rowsNumber.current = response.rows;
      setRows(response.data);
      setLoading(false);
    })();
  }, [page]);

  const handlePageChange = (params) => {
    setPage(params.page + 1);
  };

  const downloadDraft = async (id) => {
    const item = rows.find((item) => item.id === id);
    try {
      if (item) {
        setLoadingFile(true);
        const response = (await getReceiptPdf(item)).data;
        const file = await b64toBlob(response.base64);
        FileDownload(file, `${item.title}-receipt.pdf`);
      } else {
        throw Error("Error downloading receipt");
      }
    } catch (e) {
      toast.error(e);
    } finally {
      setLoadingFile(false);
    }
  };

  const columns = [
    { field: "email", headerName: "User Account Email", flex: 2 },
    { field: "title", headerName: "Draft Title", flex: 2 },
    {
      field: "printed_date",
      headerName: "Date Printed",
      width: 150,
      valueFormatter: ({ value }) => dayjs(value).format("MM-DD-YYYY"),
    },
    { field: "reading_time", headerName: "Reading Time", flex: 1 },
    {
      field: "reading_ease",
      headerName: "Overall - Reading Ease Score",
      flex: 1,
    },
    { field: "grade", headerName: "Overall - Grade Level Score", flex: 1 },
    {
      field: "sentence_count",
      headerName: "Total # of Sentences",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Print Receipt",
      renderCell: ({ value }) => (
        <IconButton onClick={() => downloadDraft(value)}>
          <InsertDriveFile />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Receipts</title>
      </Helmet>
      <Header />
      <Container className={classes.container}>
        {loadingFile && <Loading />}
        <Grid container className={classes.gridContainer}>
          <Grid container item md={12} xs={12} className={classes.headerTitle}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                Print Receipts
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.contentWrapper}>
          <Grid container item xs={12} className={classes.cardsGrid}>
            <DataGrid
              loading={loading}
              pageSize={10}
              autoHeight
              rows={rows}
              columns={columns}
              rowCount={rowsNumber.current}
              paginationMode="server"
              onPageChange={handlePageChange}
              pagination
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Receipts;
