import instance from "../instance";

export const getHeadings = async () => {
  try {
    return await instance.get("/article/heading");
  } catch (e) {
    throw e.response.data.message;
  }
};

export const updateArticle = async (id, headingId, data) => {
  try {
    return await instance.post(`/article/${id}/${headingId}`, data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const getArticle = async (id) => {
  try {
    return await instance.get(`/article/${id}`);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const downloadArticlePdf = async (id) => {
  try {
    return await instance.get(`/pdf/${id}`);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const downloadArticlePdfPreview = async (id) => {
  try {
    return await instance.get(`/pdf_copy/${id}`);
  } catch (e) {
    throw e.response.data.message;
  }
};
