import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Typography, Box } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";

import {
  ADD_COMMENT_IF_GLOSSARY,
  GET_IF_GLOSSARY,
  getIfGlossary,
} from "../store/actions/glossary";
import {
  FeedbackDialog,
  renderCellExpand,
  SearchInput,
  Loading,
} from "../components";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
  boxWrapper: {
    marginTop: 94,
  },
  btn: {
    marginBottom: 20,
  },
  dataGrid: {
    overflowX: "scroll",
  },
});

const IfGlossary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const glossary = useSelector((state) => state.glossary);
  const [search, setSearch] = useState("");
  const lastLocation = useLastLocation();
  const history = useHistory();

  const isLastLocationDraft = lastLocation?.pathname.indexOf("draft") !== -1;

  useEffect(() => {
    dispatch(getIfGlossary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtered =
    search === ""
      ? glossary.ifGlossary
      : glossary.ifGlossary.filter(
          (item) =>
            item.terms.toLowerCase().includes(search.toLowerCase()) ||
            item.defination.toLowerCase().includes(search.toLowerCase())
        );

  const columns = [
    { field: "terms", headerName: "Term", flex: 1 },
    {
      field: "defination",
      headerName: "Definition / Plain-English Substitute",
      flex: 2,
      renderCell: renderCellExpand,
    },
    {
      field: "id",
      headerName: "Feedback",
      renderCell: ({ value }) => <FeedbackDialog id={value} />,
      sortable: false,
      filterable: false,
      width: 120,
    },
  ];

  return (
    <>
      <Helmet>
        <title>IF Glossary</title>
      </Helmet>
      <Header />
      <Container className={classes.container}>
        {glossary.isLoading[ADD_COMMENT_IF_GLOSSARY.REQUEST] && <Loading />}
        <Grid
          container
          direction="row"
          className={classes.container}
          spacing={3}
        >
          <Grid item xs={12} spacing={3}>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              display="flex"
              className={classes.boxWrapper}
            >
              <Typography variant="h3">Impact Fund Glossary</Typography>
              {isLastLocationDraft && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(lastLocation?.pathname)}
                >
                  Back to draft
                </Button>
              )}
            </Box>
            <SearchInput
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Search"
            />
            <DataGrid
              loading={glossary.isLoading[GET_IF_GLOSSARY.REQUEST]}
              autoHeight
              rows={filtered}
              columns={columns}
              classes={{ root: classes.dataGrid }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default IfGlossary;
