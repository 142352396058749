import { IoMdArrowDropright } from "react-icons/io";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: "100%",
    },
    list: {
      height: "30vh",
      overflow: "auto",
      width: "100%",
      marginBottom: 10,
    },
    title: {
      marginBottom: 10,
    },
    icon: {
      marginRight: 16,
      maxWidth: 25,
      width: "100%",
      color: ({ type }) => {
        switch (type) {
          case "my-glossary":
            return theme.palette.lightYellow;
          case "if-glossary":
            return theme.palette.lightBlue;
          case "legalese":
            return theme.palette.lightPink;
          default:
            return "black";
        }
      },
    },
  })
);

const NotificationIcon = ({ type }) => {
  const classes = useStyles({ type });
  return <IoMdArrowDropright size="25" className={classes.icon} />;
};

export default NotificationIcon;
