import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  createStyles,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { getMonthName } from "../helpers";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GET_USER_STAT, getUserStatistics } from "../store/actions/admin";
import { AdminTable, Chart, Loading } from "../components";
import { theme } from "../providers";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: "100%",
      paddingTop: 94,
      paddingBottom: 50,
    },
    gridContainer: {
      backgroundColor: theme.palette.grey["200"],
    },
    btn: {
      marginBottom: 20,
    },
    headerTitle: {
      backgroundColor: theme.palette.grey["500"],
      padding: theme.spacing(2),
    },
    headerSearch: {
      backgroundColor: theme.palette.grey["400"],
      padding: "10px 0",
    },
    cardsGrid: {
      padding: "15px",
      [theme.breakpoints.down("xs")]: {
        padding: "15px 0 0 0",
      },
    },
    contentWrapper: {
      backgroundColor: "white",
    },
    title: {
      color: "white",
    },
    termsTable: {
      marginTop: 20,
    },
    glossaryChart: {
      marginTop: 20,
    },
  })
);

const AdminDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userStat, isLoading } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getUserStatistics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>
      <Header />
      <Container className={classes.container}>
        {isLoading[GET_USER_STAT.REQUEST] && <Loading />}
        <Grid container className={classes.gridContainer}>
          <Grid
            container
            item
            md={12}
            xs={12}
            className={classes.headerTitle}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                Administrator Dashboard - Analytics
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.contentWrapper}>
          <Grid
            container
            item
            md={4}
            xs={12}
            alignContent="flex-start"
            className={classes.cardsGrid}
          >
            <Chart
              color={theme.palette.amber}
              name="# of new registrations per month"
              data={userStat.total_number_of_registration_per_month}
            />
            <AdminTable
              title="User Registration"
              data={[
                {
                  title: "# of New Registrations:\n(Last 14 days)",
                  value: userStat.new_users_in_14_days,
                },
              ]}
            />
            <AdminTable
              title="Registered Users"
              color="light"
              data={[
                {
                  title: "Total # of Registered Users",
                  value: userStat.total_user_count,
                },
                {
                  title: "Active Users (#):\n(Active in last 14 days)",
                  value: userStat.total_active_user,
                },
                {
                  title: "Active Users (%):\n(% of Total)",
                  value: `${(
                    (userStat.total_active_user / userStat.total_user_count) *
                    100
                  ).toFixed(2)}%`,
                },
                {
                  title: "Inactive Users (#):\n(Not Active in last 14 days)",
                  value: userStat.total_inactive_user,
                },
                {
                  title: "Inactive Users (%):\n(% of Total)",
                  value: `${(
                    (userStat.total_inactive_user / userStat.total_user_count) *
                    100
                  ).toFixed(2)}%`,
                },
              ]}
            />
          </Grid>
          <Grid
            container
            item
            md={4}
            xs={12}
            alignContent="flex-start"
            className={classes.cardsGrid}
          >
            <Chart
              color={theme.palette.indigo}
              name="Active Drafts"
              data={userStat.total_number_of_draft_per_month}
            />
            <AdminTable
              title="Active Drafts"
              data={[
                {
                  title: "# of Active Drafts:\n(Edited in last 14 days)",
                  value: userStat.active_draft,
                },
                {
                  title: "# of Inactive Drafts:\n(Not edited in last 14 days)",
                  value: userStat.inactive_draft,
                },
              ]}
            />
            <AdminTable
              title="Drafts - Overall"
              color="light"
              data={[
                {
                  title: "Total # of Drafts:",
                  value: userStat.total_draft,
                },
                {
                  title: "Avg. # of Drafts per User:",
                  value: userStat.avg_draft_per_user,
                },
              ]}
            />
            <Chart
              color={theme.palette.indigo}
              className={classes.glossaryChart}
              name="# of New Glossary Terms (From User Glossaries)"
              data={userStat.total_number_of_glossories_per_month}
            />
            <AdminTable
              title="Glossary Terms"
              data={[
                {
                  title:
                    "Total # of New Glossary Terms:\n(Terms added in 'MyGlossary')",
                  value: userStat.total_glossary_count,
                },
                {
                  title: "Avg. # of New Glossary Terms\nper User:",
                  value: userStat.glossary_per_user,
                },
              ]}
            />
          </Grid>
          <Grid
            container
            item
            md={4}
            xs={12}
            alignContent="flex-start"
            className={classes.cardsGrid}
          >
            <Chart
              color={theme.palette.green}
              name="# of Printed Drafts"
              data={userStat.total_number_of_printed_drafts_per_month}
            />
            <AdminTable
              title="Printed Templates"
              data={[
                {
                  title: "# of Printed Templates:\n(Last 14 days)",
                  value: userStat.printed_templates_in_14_days,
                },
              ]}
            />
            <AdminTable
              title="Printed Templates - Overall"
              color="light"
              data={[
                {
                  title: "Total # of Printed Templates:\n(All time)",
                  value: userStat.printed_templates,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AdminDashboard;
