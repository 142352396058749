import React from "react";
import InputMask from "react-input-mask";
import { TextField } from "@material-ui/core";

const MaskedInput = ({ mask, ...other }) => {
  return (
    <InputMask mask={mask}>
      {(inputProps) => <TextField {...{ ...inputProps, ...other }} />}
    </InputMask>
  );
};

export default MaskedInput;
