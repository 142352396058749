import React, { useState } from "react";
import { createStyles, Grid, Typography } from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { makeStyles } from "@material-ui/core/styles";
import { GetApp } from "@material-ui/icons";
import { DraftModal } from "./index";
import DeleteDialog from "./DeleteDialog";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { downloadArticlePdf } from "../api";
import { toast } from "material-react-toastify";
import FileDownload from "js-file-download";
import { b64toBlob } from "../helpers";

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.grey["100"],
      marginBottom: 20,
      padding: "10px 20px",
      borderRadius: 15,
      height: 111,
    },
    button: {
      marginBottom: 5,
    },
    btnContainer: {
      padding: "2px 10px",
      justifyContent: "flex-start",
    },
  })
);

const DraftCard = ({ item }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { user_name } = useSelector((state) => state.user.profile);
  const modifierName =
    user_name === item.user_name
      ? "You"
      : `${item.first_name} ${item.last_name}`;

  const downloadDraft = async () => {
    try {
      setLoading(true);
      const response = (await downloadArticlePdf(item.id)).data;
      const file = await b64toBlob(response.base64);
      FileDownload(file, `${item.draft_name}.pdf`);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid container item className={classes.card}>
      <Grid
        xs={7}
        md={8}
        lg={10}
        container
        item
        justify="space-between"
        direction="column"
      >
        <Typography variant="h6">{item.draft_name}</Typography>
        <div>
          <Typography variant="body2">
            {`Last edited by: ${modifierName}, on ${dayjs(
              item.modified_date
            ).format("MM/DD/YYYY")}`}
          </Typography>
          <Typography variant="body2">{`${item.reading_time} Min Read`}</Typography>
        </div>
      </Grid>
      <Grid item xs={5} md={4} lg={2}>
        <DeleteDialog id={item.id} />
        <DraftModal type="edit" id={item.id} />
        <LoadingButton
          loading={loading}
          variant="contained"
          color="default"
          classes={{
            root: classes.btnContainer,
          }}
          startIcon={<GetApp />}
          size="small"
          fullWidth
          onClick={downloadDraft}
        >
          Download
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default DraftCard;
