import {
  CLEAR_ACTIVE_DRAFT,
  CLEAR_REDIRECT,
  CREATE_DRAFT,
  DELETE_DRAFT,
  GET_ALL_DRAFTS,
  GET_DRAFT,
  UPDATE_DRAFT,
} from "../../actions/draft";

const initialState = {
  allDrafts: [],
  isLoading: {},
  error: {},
  activeDraft: {},
  redirectTo: null,
};

const draft = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DRAFTS.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_ALL_DRAFTS.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_ALL_DRAFTS.ERROR]: undefined,
        },
      };

    case GET_ALL_DRAFTS.SUCCESS:
      return {
        ...state,
        allDrafts: action.payload.allDrafts,
        isLoading: {
          ...state.isLoading,
          [GET_ALL_DRAFTS.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_ALL_DRAFTS.ERROR]: undefined,
        },
      };

    case GET_ALL_DRAFTS.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_ALL_DRAFTS.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_ALL_DRAFTS.REQUEST]: false,
        },
      };

    case DELETE_DRAFT.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [DELETE_DRAFT.REQUEST]: true,
        },
        error: {
          ...state.error,
          [DELETE_DRAFT.ERROR]: undefined,
        },
      };

    case DELETE_DRAFT.SUCCESS:
      return {
        ...state,
        allDrafts: state.allDrafts.filter((el) => el.id !== action.payload.id),
        isLoading: {
          ...state.isLoading,
          [DELETE_DRAFT.REQUEST]: false,
        },
        error: {
          ...state.error,
          [DELETE_DRAFT.ERROR]: undefined,
        },
      };

    case DELETE_DRAFT.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [DELETE_DRAFT.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [DELETE_DRAFT.REQUEST]: false,
        },
      };

    case CREATE_DRAFT.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [CREATE_DRAFT.REQUEST]: true,
        },
        error: {
          ...state.error,
          [CREATE_DRAFT.ERROR]: undefined,
        },
      };

    case CREATE_DRAFT.SUCCESS:
      return {
        ...state,
        allDrafts: [...state.allDrafts, action.payload.item],
        redirectTo: action.payload.item.id,
        isLoading: {
          ...state.isLoading,
          [CREATE_DRAFT.REQUEST]: false,
        },
        error: {
          ...state.error,
          [CREATE_DRAFT.ERROR]: undefined,
        },
      };

    case CREATE_DRAFT.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [CREATE_DRAFT.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [CREATE_DRAFT.REQUEST]: false,
        },
      };

    case UPDATE_DRAFT.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [UPDATE_DRAFT.REQUEST]: true,
        },
        error: {
          ...state.error,
          [UPDATE_DRAFT.ERROR]: undefined,
        },
      };

    case UPDATE_DRAFT.SUCCESS:
      return {
        ...state,
        allDrafts: state.allDrafts.map((el) =>
          el.id === action.payload.updatedItem.id
            ? action.payload.updatedItem
            : el
        ),
        isLoading: {
          ...state.isLoading,
          [UPDATE_DRAFT.REQUEST]: false,
        },
        error: {
          ...state.error,
          [UPDATE_DRAFT.ERROR]: undefined,
        },
      };

    case UPDATE_DRAFT.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [UPDATE_DRAFT.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [UPDATE_DRAFT.REQUEST]: false,
        },
      };

    case GET_DRAFT.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_DRAFT.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_DRAFT.ERROR]: undefined,
        },
      };

    case GET_DRAFT.SUCCESS:
      return {
        ...state,
        activeDraft: action.payload.item,
        isLoading: {
          ...state.isLoading,
          [GET_DRAFT.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_DRAFT.ERROR]: undefined,
        },
      };

    case GET_DRAFT.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_DRAFT.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_DRAFT.REQUEST]: false,
        },
      };

    case CLEAR_ACTIVE_DRAFT:
      return {
        ...state,
        activeDraft: {},
      };

    case CLEAR_REDIRECT:
      return {
        ...state,
        redirectTo: null,
      };

    default:
      return state;
  }
};

export default draft;
