import React from "react";
import {
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { useDispatch } from "react-redux";
import { addCommentIfGlossary } from "../store/actions/glossary";

export default function FeedbackDialog({ id }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    dispatch(addCommentIfGlossary(data, id));
    handleClose();
  };

  return (
    <div>
      <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
        <FeedbackIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Feedback"
              type="text"
              name="comment"
              fullWidth
              inputRef={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
              error={errors.comment}
              helperText={errors.comment?.message}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={onSubmit} color="primary">
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
