import React from "react";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { deleteMyGlossary } from "../store/actions/glossary";
import { deleteDraft } from "../store/actions/draft";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles({
  button: {
    marginBottom: 5,
  },
  btnContainer: {
    padding: "2px 10px",
    justifyContent: "flex-start",
  },
});

export default function DeleteDialog({ id, type }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteItem = () => {
    if (type === "glossary") {
      dispatch(deleteMyGlossary(id));
    } else {
      dispatch(deleteDraft(id));
    }
    handleClose();
  };

  return (
    <div>
      {type === "glossary" ? (
        <IconButton
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
        >
          <DeleteIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          classes={{
            containedSizeSmall: classes.btnContainer,
          }}
          startIcon={<Delete />}
          size="small"
          fullWidth
          onClick={handleClickOpen}
        >
          Delete
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Delete {type === "glossary" ? "term" : "draft"}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={deleteItem} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
