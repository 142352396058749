import instance from "../instance";

export const signUp = async (data) => {
  try {
    return await instance.post("/signup", data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const signIn = async (data) => {
  try {
    return await instance.post("/signin", data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const forgotPasswordReq = async (data) => {
  try {
    return await instance.post("/forgot", data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const forgotPasswordConfirm = async (data, token) => {
  try {
    return await instance.post(`/forgot/${token}`, data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const usernameAvailable = async (name) => {
  try {
    return await instance.get(`/username/${name}`);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const updateUser = async (data) => {
  try {
    return await instance.put("/user", data);
  } catch (e) {
    throw e.response.data.message;
  }
};

export const getProfile = async () => {
  try {
    return await instance.get("/user");
  } catch (e) {
    throw e.response.data.message;
  }
};

export const sendAppFeedback = async (data) => {
  try {
    return await instance.post("/feedback/app", data);
  } catch (e) {
    throw e.response.data.message;
  }
};
