import React, { useEffect, useState } from "react";
import {
  Container,
  createStyles,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  List,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../layout";
import { useSelector, useDispatch } from "react-redux";

import { Helmet } from "react-helmet";
import {
  FeedbackCard,
  FeedbackItem,
  Loading,
  SearchInput,
} from "../components";
import { Pagination } from "material-lab-old";
import { GET_APP_FEEDBACK, getAppFeedback } from "../store/actions/admin";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: "100%",
      paddingTop: 94,
      paddingBottom: 50,
    },
    gridContainer: {
      backgroundColor: theme.palette.grey["200"],
    },
    btn: {
      marginBottom: 20,
    },
    headerTitle: {
      backgroundColor: theme.palette.grey["500"],
      padding: theme.spacing(2),
    },
    headerSearch: {
      backgroundColor: theme.palette.grey["200"],
      padding: "20px",
    },
    cardsGrid: {
      padding: "15px",
      [theme.breakpoints.down("xs")]: {
        padding: "15px 0 0 0",
      },
    },
    contentWrapper: {
      backgroundColor: "white",
    },
    title: {
      color: "white",
    },
    filtersWrapper: {
      paddingTop: theme.spacing(1),
      paddingRight: "15px",
      "& > * + *": {
        paddingLeft: theme.spacing(3),
      },
    },
    textWrapper: {
      marginBottom: "3px",
    },
    pagination: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "30px",
      marginBottom: "20px",
    },
    feedbackList: {
      padding: theme.spacing(2),
    },
    feedbackCard: {
      padding: theme.spacing(2),
    },
  })
);

const Feedback = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { feedbackPages, feedbackRows, feedbacks, isLoading } = useSelector(
    (state) => state.admin
  );
  const [activePage, setActivePage] = useState(1);
  const [message, onChangeMessage] = useState("");
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    const filteredQuery = Object.fromEntries(
      Object.entries({ status, date, message }).filter(
        ([key, value]) => value !== "" && value !== "all"
      )
    );
    dispatch(getAppFeedback(activePage, filteredQuery));
  }, [activePage, message, date, status]);

  const showingEntries =
    10 * activePage > feedbackRows ? feedbackRows : 10 * activePage;

  return (
    <>
      <Helmet>
        <title>Feedback</title>
      </Helmet>
      <Header />
      {isLoading[GET_APP_FEEDBACK.REQUEST] && <Loading />}
      <Container className={classes.container}>
        <Grid container className={classes.gridContainer}>
          <Grid container item md={12} xs={12} className={classes.headerTitle}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                Feedback
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.contentWrapper}>
          <Grid container item xs={12} className={classes.headerSearch}>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <SearchInput
                  value={message}
                  onChange={(e) => onChangeMessage(e.target.value)}
                  noMargin
                  placeholder="Search all the documents"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Grid container item xs={12} className={classes.filtersWrapper}>
              <Grid
                item
                container
                xs={2}
                alignItems="flex-end"
                justify="flex-end"
                className={classes.textWrapper}
              >
                <Typography variant="body1" component="span">
                  Filter by
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <InputLabel id="date-label">Date</InputLabel>
                  <Select
                    autoWidth
                    labelId="date-label"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="1">Today</MenuItem>
                    <MenuItem value="14">Last two weeks</MenuItem>
                    <MenuItem value="30">Month</MenuItem>
                    <MenuItem value="90">Quarter</MenuItem>
                    <MenuItem value="365">Year</MenuItem>
                    <MenuItem value="all">All time</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    autoWidth
                    labelId="status-label"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="CON">Confirmed</MenuItem>
                    <MenuItem value="DIS">Dismissed</MenuItem>
                    <MenuItem value="READ">Read</MenuItem>
                    <MenuItem value="UNREAD">Unread</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid
              item
              container
              xs={12}
              md={6}
              className={classes.feedbackList}
              direction="column"
            >
              <List>
                {feedbacks.map((item) => (
                  <FeedbackItem item={item} key={item.id} />
                ))}
              </List>
              <Typography variant="body2">{`Showing ${showingEntries} of ${feedbackRows}`}</Typography>
              <Pagination
                page={activePage}
                onChange={(event, page) => setActivePage(page)}
                count={feedbackPages}
                color="primary"
                className={classes.pagination}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.feedbackCard}>
              <FeedbackCard />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Feedback;
