import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Logo } from "../layout";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
  title: {
    marginBottom: 20,
  },
});

const ConfirmRegister = () => {
  const classes = useStyles();
  const { email } = useSelector((state) => state.user.profile);

  return (
    <>
      <Helmet>
        <title>Confirm Register</title>
      </Helmet>
      <Container className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid
            container
            md={6}
            spacing={3}
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Typography
              variant="h4"
              component="h4"
              align="center"
              className={classes.title}
            >
              Thanks for registering!
            </Typography>
            <Typography
              variant="body1"
              component="p"
              align="center"
              className={classes.title}
            >
              {`We sent you a confirmation email at ${email}. Please confirm your email and complete the registration process.`}
            </Typography>
            <Logo />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ConfirmRegister;
