import React, { useEffect, useRef } from "react";
import {
  Container,
  createStyles,
  Grid,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../layout";
import { useDispatch, useSelector } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorNotifications,
  HeadingsList,
  SearchArticle,
  TextAnalytics,
  Editor,
  PdfPreviewModal,
  NavigateHeaders,
} from "../components";
import {
  getArticle,
  clearArticle,
  setActiveHeading,
} from "../store/actions/article";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { clearActiveDraft } from "../store/actions/draft";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "100%",
      paddingTop: 94,
    },
    title: {
      marginBottom: 20,
    },
    gridContainer: {
      backgroundColor: theme.palette.grey["200"],
    },
    headerTitle: {
      backgroundColor: theme.palette.grey["500"],
    },
    headerSearch: {
      backgroundColor: theme.palette.grey["400"],
      padding: "10px 0",
    },
    contentWrapper: {
      height: "100%",
    },
    headingInputTitleWrapper: {
      backgroundColor: theme.palette.grey["400"],
      marginBottom: 24,
      padding: 10,
    },
    headingTitle: {
      fontWeight: "bold",
    },
    editorGridWrapper: {
      height: "calc(100% - 96.5px)",
    },
    headingInput: {
      backgroundColor: "white",
      "& .MuiInputBase-input": {
        padding: 10,
      },
    },
    highlight: {
      background: "green",
    },
    caseTitle: {
      color: "white",
    },
    btn: {
      marginTop: 10,
    },
  })
);

const Draft = () => {
  const leftSectionRef = useRef();
  const titleRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { activeHeading, name } = useSelector((state) => state.article);

  const editorRef = useRef();

  useEffect(() => {
    dispatch(getArticle(id));

    return () => {
      dispatch(clearArticle());
      dispatch(clearActiveDraft());
      dispatch(setActiveHeading(1));
    };
  }, []);

  const overallAnalytics = () => {
    history.push(`/draft/${id}/analytics`);
  };

  const maxHeight =
    leftSectionRef.current?.offsetHeight - titleRef.current?.offsetHeight - 96;

  return (
    <>
      <Helmet>
        <title>{`Article - ${name}`}</title>
      </Helmet>
      <Header />
      <Container className={classes.container}>
        <Grid container className={classes.gridContainer} spacing={3}>
          <Grid
            container
            md={8}
            item
            className={classes.headerTitle}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6" className={classes.caseTitle}>
                {name}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={4}
            className={classes.headerSearch}
            justify="center"
          >
            <Grid item xs={10}>
              <SearchArticle editorRef={editorRef} />
            </Grid>
          </Grid>
          <Grid container item className={classes.contentWrapper} spacing={3}>
            <Grid container item md={2} direction="column">
              <Grid item>
                <Grid item>
                  <HeadingsList />
                </Grid>
                <Grid container item justify="center">
                  <PdfPreviewModal />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={overallAnalytics}
                    fullWidth
                    className={classes.btn}
                  >
                    Overall Analytics
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={8}>
              <Grid
                container
                item
                className={classes.headingInputTitleWrapper}
                alignItems="center"
                ref={titleRef}
              >
                <Grid item container direction="row" alignItems="center">
                  <Grid item md={10}>
                    <Typography
                      className={classes.headingTitle}
                      variant="body1"
                    >
                      {`${activeHeading.name} - ${activeHeading.title}`}
                    </Typography>
                    <Typography variant="subtitle1">
                      {activeHeading.sub_title}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Box justifyContent="flex-end" display="flex">
                      <NavigateHeaders />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item className={classes.editorGridWrapper}>
                <Editor maxHeight={maxHeight} editorRef={editorRef} />
              </Grid>
            </Grid>
            <Grid container item md={2} direction="column">
              <Grid item ref={leftSectionRef}>
                <Grid item>
                  <EditorNotifications />
                </Grid>
                <Grid item>
                  <TextAnalytics />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Draft;
