import { Typography } from "@material-ui/core";

export default function getGradeLevel(value) {
  if (value > 100) {
    return (
      <Typography variant="caption" color="textSecondary">
        Consider shortening sentences & using clear language
      </Typography>
    );
  }
  if (value >= 90 && value <= 100) {
    return "5th gr.";
  }
  if (value >= 80 && value <= 89) {
    return "6th gr.";
  }
  if (value >= 70 && value <= 79) {
    return "7th gr.";
  }
  if (value >= 60 && value <= 69) {
    return "8th-9th gr.";
  }
  if (value >= 50 && value <= 59) {
    return "10th-12th gr.";
  }
  if (value >= 30 && value <= 49) {
    return "College";
  }
  if (value >= 10 && value <= 29) {
    return "College grad.";
  }
  if (value >= 1 && value <= 9) {
    return "Professional";
  }
  return "";
}
