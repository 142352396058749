import React from "react";
import { Route as ReactRoute, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRoute = ({ children, ...rest }) => {
  const { isAuthenticated, isAdmin } = useSelector((state) => state.user);

  if (isAuthenticated) {
    if (isAdmin) {
      return <ReactRoute {...rest} render={() => children} />;
    }
    return <Redirect to="/home" />;
  }

  return <Redirect to="/signin" />;
};

export default AdminRoute;
