import {
  GET_MY_GLOSSARY,
  UPDATE_MY_GLOSSARY,
  DELETE_MY_GLOSSARY,
  ADD_MY_GLOSSARY,
  ADD_COMMENT_IF_GLOSSARY,
  GET_IF_GLOSSARY,
  GET_LEGALESE,
} from "../../actions/glossary";

const initialState = {
  isLoading: {},
  error: {},
  ifGlossary: [],
  myGlossary: [],
  legalese: [],
};

const glossary = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEGALESE.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_LEGALESE.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_LEGALESE.ERROR]: undefined,
        },
      };

    case GET_LEGALESE.SUCCESS:
      return {
        ...state,
        legalese: action.payload.legalese,
        isLoading: {
          ...state.isLoading,
          [GET_LEGALESE.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_LEGALESE.ERROR]: undefined,
        },
      };

    case GET_LEGALESE.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_LEGALESE.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_LEGALESE.REQUEST]: false,
        },
      };
    case GET_MY_GLOSSARY.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_MY_GLOSSARY.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_MY_GLOSSARY.ERROR]: undefined,
        },
      };

    case GET_MY_GLOSSARY.SUCCESS:
      return {
        ...state,
        myGlossary: action.payload.myGlossary,
        isLoading: {
          ...state.isLoading,
          [GET_MY_GLOSSARY.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_MY_GLOSSARY.ERROR]: undefined,
        },
      };

    case GET_MY_GLOSSARY.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_MY_GLOSSARY.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_MY_GLOSSARY.REQUEST]: false,
        },
      };

    case UPDATE_MY_GLOSSARY.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [UPDATE_MY_GLOSSARY.REQUEST]: true,
        },
        error: {
          ...state.error,
          [UPDATE_MY_GLOSSARY.ERROR]: undefined,
        },
      };

    case UPDATE_MY_GLOSSARY.SUCCESS:
      return {
        ...state,
        myGlossary: state.myGlossary.map((el) =>
          el.id === action.payload.updatedItem.id
            ? action.payload.updatedItem
            : el
        ),
        isLoading: {
          ...state.isLoading,
          [UPDATE_MY_GLOSSARY.REQUEST]: false,
        },
        error: {
          ...state.error,
          [UPDATE_MY_GLOSSARY.ERROR]: undefined,
        },
      };

    case UPDATE_MY_GLOSSARY.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [UPDATE_MY_GLOSSARY.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [UPDATE_MY_GLOSSARY.REQUEST]: false,
        },
      };

    case DELETE_MY_GLOSSARY.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [DELETE_MY_GLOSSARY.REQUEST]: true,
        },
        error: {
          ...state.error,
          [DELETE_MY_GLOSSARY.ERROR]: undefined,
        },
      };

    case DELETE_MY_GLOSSARY.SUCCESS:
      return {
        ...state,
        myGlossary: state.myGlossary.filter(
          (el) => el.id !== action.payload.id
        ),
        isLoading: {
          ...state.isLoading,
          [DELETE_MY_GLOSSARY.REQUEST]: false,
        },
        error: {
          ...state.error,
          [DELETE_MY_GLOSSARY.ERROR]: undefined,
        },
      };

    case DELETE_MY_GLOSSARY.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [DELETE_MY_GLOSSARY.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [DELETE_MY_GLOSSARY.REQUEST]: false,
        },
      };

    case ADD_MY_GLOSSARY.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ADD_MY_GLOSSARY.REQUEST]: true,
        },
        error: {
          ...state.error,
          [ADD_MY_GLOSSARY.ERROR]: undefined,
        },
      };

    case ADD_MY_GLOSSARY.SUCCESS:
      const { id, terms, defination } = action.payload.item;
      return {
        ...state,
        myGlossary: [...state.myGlossary, { id, terms, defination }],
        isLoading: {
          ...state.isLoading,
          [ADD_MY_GLOSSARY.REQUEST]: false,
        },
        error: {
          ...state.error,
          [ADD_MY_GLOSSARY.ERROR]: undefined,
        },
      };

    case ADD_MY_GLOSSARY.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [ADD_MY_GLOSSARY.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [ADD_MY_GLOSSARY.REQUEST]: false,
        },
      };

    case ADD_COMMENT_IF_GLOSSARY.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ADD_COMMENT_IF_GLOSSARY.REQUEST]: true,
        },
        error: {
          ...state.error,
          [ADD_COMMENT_IF_GLOSSARY.ERROR]: undefined,
        },
      };

    case ADD_COMMENT_IF_GLOSSARY.SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ADD_COMMENT_IF_GLOSSARY.REQUEST]: false,
        },
        error: {
          ...state.error,
          [ADD_COMMENT_IF_GLOSSARY.ERROR]: undefined,
        },
      };

    case ADD_COMMENT_IF_GLOSSARY.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [ADD_COMMENT_IF_GLOSSARY.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [ADD_COMMENT_IF_GLOSSARY.REQUEST]: false,
        },
      };

    case GET_IF_GLOSSARY.REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [GET_IF_GLOSSARY.REQUEST]: true,
        },
        error: {
          ...state.error,
          [GET_IF_GLOSSARY.ERROR]: undefined,
        },
      };

    case GET_IF_GLOSSARY.SUCCESS:
      return {
        ...state,
        ifGlossary: action.payload.ifGlossary,
        isLoading: {
          ...state.isLoading,
          [GET_IF_GLOSSARY.REQUEST]: false,
        },
        error: {
          ...state.error,
          [GET_IF_GLOSSARY.ERROR]: undefined,
        },
      };

    case GET_IF_GLOSSARY.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [GET_IF_GLOSSARY.ERROR]: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          [GET_IF_GLOSSARY.REQUEST]: false,
        },
      };
    default:
      return state;
  }
};

export default glossary;
