import React, { useState, useEffect } from "react";
import {
  AppBar,
  Container,
  Button,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Logo } from "./";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import { breakpoints } from "../config";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, signOut as signOutAction } from "../store/actions/user";
import { HeaderButton } from "../components";

const useStyles = makeStyles(() => ({
  header: {
    background: "white",
  },
  menuButton: {},
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
  },
  drawer: {
    width: 240,
  },
  icon: {
    paddingLeft: 0,
  },
  desktopHeaderWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { breakpoint } = useBreakpoint(breakpoints, "desktop");
  const { user_name } = useSelector((state) => state.user.profile);
  const { isAdmin } = useSelector((state) => state.user);

  const [drawer, setDrawer] = useState(false);
  const headers = isAdmin ? adminHeadersData : userHeadersData;

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const menuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuClose = () => {
    setAnchorEl(null);
  };

  const openDrawer = () => {
    setDrawer((prevState) => !prevState);
  };

  const signOut = async () => {
    menuClose();
    dispatch(signOutAction());
    history.push("/signin");
  };

  const desktopHeader = () => (
    <div className={classes.desktopHeaderWrapper}>
      <Logo />
      <div>
        {headers.map(({ label, href }) => {
          return (
            <HeaderButton
              {...{
                key: label,
                color: "primary",
                to: href,
                component: Link,
              }}
            >
              {label}
            </HeaderButton>
          );
        })}
      </div>
    </div>
  );

  const mobileHeader = () => {
    return (
      <>
        <IconButton
          {...{
            edge: "start",
            color: "primary",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: openDrawer,
            className: classes.icon,
          }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <Logo />
        <Drawer
          anchor="left"
          open={drawer}
          onClose={openDrawer}
          className={classes.drawer}
          classes={{
            paper: classes.drawer,
          }}
        >
          <List>
            {headers.map((item) => (
              <ListItem
                {...{
                  button: true,
                  key: item.label,
                  to: item.href,
                  component: Link,
                }}
              >
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </>
    );
  };

  return (
    <AppBar className={classes.header}>
      <Container>
        <Toolbar className={classes.toolbar}>
          {breakpoint === "desktop" ? desktopHeader() : mobileHeader()}
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            variant="contained"
            onClick={menuClick}
          >
            {`Hi ${user_name ?? "user"}`}
            <KeyboardArrowDownIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={menuClose}
          >
            <MenuItem
              component={Link}
              to="/account-details"
              onClick={menuClose}
            >
              My account
            </MenuItem>
            <MenuItem onClick={signOut}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;

const userHeadersData = [
  {
    label: "Home",
    href: "/home",
  },
  {
    label: "Impact Fund Glossary",
    href: "/if-glossary",
  },
  {
    label: "My Glossary",
    href: "/my-glossary",
  },
];

const adminHeadersData = [
  {
    label: "Home",
    href: "/dashboard-admin",
  },
  {
    label: "Impact Fund Glossary",
    href: "/if-glossary",
  },
  {
    label: "My Glossary",
    href: "/my-glossary",
  },
  {
    label: "Receipts",
    href: "/receipts",
  },
  { label: "User Feedback", href: "/user-feedback" },
];
