import { createStyles, makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      html: {
        height: "100%",
        width: "100%",
        "box-sizing": "border-box",
      },
      img: {
        maxWidth: "100%",
        height: "auto",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
        background: ({ isAuthenticated }) =>
          isAuthenticated ? null : "#FFF4F1",
      },
      ".MuiDataGrid-row, .MuiDataGrid-root .MuiDataGrid-cell, .rendering-zone": {
        "max-height": "none !important",
        textOverflow: "unset",
        whiteSpace: "unset",
        [theme.breakpoints.down("md")]: {
          maxHeight: "52px !important",
        },
      },
      ".MuiDataGrid-root .MuiDataGrid-window": {
        position: "relative !important",
      },
      ".MuiDataGrid-root .MuiDataGrid-viewport": {
        "max-height": "none !important",
      },
      ".MuiDataGrid-root": { height: "auto !important" },
    },
  })
);

const GlobalStyles = () => {
  const { isAuthenticated } = useSelector((state) => state.user);
  useStyles({ isAuthenticated });
  return null;
};

export default GlobalStyles;
