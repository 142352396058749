import React, { forwardRef } from "react";
import { Paper, InputBase, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: ({ noMargin }) => (noMargin ? "0" : "30px"),
    marginTop: ({ noMargin }) => (noMargin ? "0" : "30px"),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const SearchInput = forwardRef(
  (
    { onClickSearch, value, onChange, placeholder, noMargin, ...props }: props,
    ref
  ) => {
    const classes = useStyles({ noMargin });

    return (
      <Paper component="form" className={classes.root} ref={ref}>
        <InputBase
          value={value}
          onChange={onChange}
          className={classes.input}
          placeholder={placeholder}
          {...props}
        />
        <IconButton className={classes.iconButton} onClick={onClickSearch}>
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  }
);

export default SearchInput;
