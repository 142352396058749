import React, { useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clear, resetPasswordRequest } from "../store/actions/user";
import { useHistory } from "react-router-dom";
import { RESET_PWD } from "../store/actions/user";
import { Loading } from "../components";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
  title: {
    marginBottom: 20,
  },
  input: {
    marginTop: 50,
    marginBottom: 50,
  },
});

const ForgotPasswordRequest = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    dispatch(resetPasswordRequest(data));
  };

  useEffect(() => {
    if (user.resetToken) {
      history.push(`/forgot/${user.resetToken}`);
    }

    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.resetToken]);

  return (
    <>
      <Helmet>
        <title>Forgot password</title>
      </Helmet>
      <Container className={classes.container}>
        {user.isLoading[RESET_PWD.REQUEST] && <Loading />}
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid
            container
            md={6}
            spacing={3}
            justify="center"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h4"
                align="center"
                className={classes.title}
              >
                Forgot your password?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.input}
                placeholder="Email address"
                fullWidth={true}
                name="email"
                type="email"
                inputRef={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Email should have form user@email.com",
                  },
                })}
                error={errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={9}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Send reset code
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ForgotPasswordRequest;
