import React, { useState, useRef, useEffect } from "react";
import { SearchInput } from "./";
import { useSelector, useDispatch } from "react-redux";
import {
  Popper,
  Fade,
  makeStyles,
  Paper,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import { setActiveHeading, setSearch } from "../store/actions/article";
import { isEmpty } from "../helpers";
import { toast } from "material-react-toastify";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  wrapper: {
    marginTop: 5,
    width: "100%",
    overflowY: "scroll",
    maxHeight: 500,
  },
  subheader: { fontSize: "1rem", fontStyle: "italic", fontWeight: "bold" },
  item: {
    "&::before": {
      position: "absolute",
      content: '"\\201D"',
      top: 0,
      right: 0,
    },
    fontStyle: "italic",
    height: "1.3rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "relative",
    paddingRight: 5,
  },
  spanWrapper: {
    color: theme.palette.green,
  },
}));

const SearchArticle = ({ editorRef }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { article, headings, search } = useSelector((state) => state.article);
  const [focus, setOnFocus] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const inputRef = useRef();

  const html_regex = /<\/?[^>]+(>|$)/g;

  function searchFilter(data, search) {
    const searchString = search.replace(/"'`/g, "").split(/\s+/);
    const regex = new RegExp(searchString, "gi");
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const { paragraph, heading_id, heading_title } = data[i];
      const found = paragraph
        .map((para) => {
          const paraWithoutHtml = para.replace(html_regex, "");
          const sent = paraWithoutHtml
            .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
            .split("|")
            .map((sentence) => {
              const strLength = sentence.length;
              const matches = [];
              while ((search = regex.exec(sentence))) {
                const splittedString = sentence
                  .toString()
                  .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
                  .split(" ");

                const index = splittedString
                  .filter((el) => el.length)
                  .findIndex((word) => search.includes(word));

                matches.push({
                  paragraph: para,
                  sentence:
                    index <= 3
                      ? `"${sentence}`
                      : `"...${sentence
                          .toString()
                          .split(" ")
                          .slice(index - 3)
                          .join(" ")}`,

                  heading_id,
                  heading_title,
                  paraWithoutHtml,
                });
              }
              return matches;
            });
          return sent.flat();
        })
        .filter((val) => val);
      if (found.length) {
        result.push(found[0]);
      }
    }
    return result.reduce((acc, curr) => {
      curr.forEach((el) => {
        acc[el.heading_id] = acc[el.heading_id] || [];
        return acc[el.heading_id].push(el);
      });
      return acc;
    }, {});
  }

  useEffect(() => {
    if (search === "") {
      setSearchResults({});
    } else {
      setSearchResults(searchFilter(article, search));
    }
  }, [search]);

  const handleClick = (item) => {
    dispatch(setActiveHeading(item.heading_id));
    editorRef.current.focus();
  };

  const onFocus = () => {
    setOnFocus(true);
  };

  const onBlur = () => {
    setOnFocus(false);
  };

  function escapeRegExp(s) {
    return s.replace(/[.*+\-?^${}()|[\]\\]/gi, "\\$&");
  }

  const regex = new RegExp(`\\b${escapeRegExp(search)}\\b`, "ig");

  const onClickSearch = () => {
    const id = Object.keys(searchResults)[0];
    if (id) {
      dispatch(setActiveHeading(Number(id)));
      editorRef.current.focus();
    } else {
      toast.warn("No results!");
    }
  };

  return (
    <>
      <SearchInput
        onFocus={onFocus}
        onBlur={onBlur}
        ref={inputRef}
        value={search}
        onChange={(e) => dispatch(setSearch(e.target.value))}
        noMargin
        placeholder="Search this Document"
        onClickSearch={onClickSearch}
      />
      <Popper
        style={{
          maxWidth: inputRef.current?.offsetWidth,
          width: "100%",
          zIndex: 100,
        }}
        open={search !== "" && !isEmpty(searchResults) && focus}
        anchorEl={inputRef.current}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.wrapper}>
              <List component="div" disablePadding>
                {Object.entries(searchResults).map(([heading, paragraphs]) => (
                  <div key={heading + paragraphs}>
                    <ListSubheader
                      disableSticky
                      classes={{ root: classes.subheader }}
                    >{`Heading ${heading}`}</ListSubheader>
                    {paragraphs.map((item) => (
                      <ListItem
                        button
                        onClick={() => handleClick(item)}
                        key={item.heading_id + item.sentence}
                      >
                        <Typography
                          component="span"
                          variant="body1"
                          className={classes.item}
                          dangerouslySetInnerHTML={{
                            __html: item.sentence.replace(regex, (match) => {
                              return `<span class="${classes.spanWrapper}">${match}</span>`;
                            }),
                          }}
                        />
                      </ListItem>
                    ))}
                  </div>
                ))}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default SearchArticle;
